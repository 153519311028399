import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import myContext from "../../../context/context";
import { API_BASE_URL } from "../../../config";
import { FaBars } from "react-icons/fa";
import styles from "../../dashboardpages/Dashboardmain/dashboard.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "../../../Redux/reducers/userSlice";

const TopNav = () => {
  const { dclose, dopenSidebar } = useContext(myContext);
  const [logoutBtn, setLogoutBtn] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state);
  const handleLogout = () => {
    dispatch(Logout());
    navigate("/");
    localStorage.clear();
  };

  return (
    <>
      <nav
        className={
          dclose ? `${styles.TopbarFull} navbar` : `${styles.Topbar} navbar`
        }
      >
        <div className={`container-fluid ${styles.topbarItems}`}>
          <div>
            <span className="navbar-brand text-light" onClick={dopenSidebar}>
              {dclose ? <FaBars className={styles.menu_bar} /> : ""}
            </span>
            <Link
              to="/dashboard"
              className={`${styles.dashboard_navlink_home_btn} `}
            >
              <span>Dashboard</span>
            </Link>
          </div>

          <div className={styles.user_pic_container}>
            <figure className={`${styles.user_img}`}>
              <img
                src={`${API_BASE_URL}/${userData?.data?.avatar}`}
                alt="user"
                width={45}
              />
              <span className="ms-3">{userData?.data?.username}</span>
            </figure>
            <span
              className={
                logoutBtn ? `${styles.logout_btn}` : `${styles.logout_btn_hide}`
              }
            >
              <button onClick={handleLogout}>Logout</button>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default TopNav;
