import React, { useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./addproperty.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { useSelector } from "react-redux";

function AddProperty() {
  const { dclose } = useContext(myContext);
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [featuredImage, setFeaturedImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);
  const userData = useSelector((state) => state);
  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // validation
  const formValidation = () => {
    if (
      title &&
      address &&
      price &&
      description &&
      featuredImage &&
      galleryImages
    ) {
      if (galleryImages.length > 10) {
        setSuccessAlert(false);
        alterValidationMessage("You Upload Maximum 10 Images");
      } else {
        return true;
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    debugger
    if (formValidation()) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("address", address);
      formData.append("price", price);
      formData.append("description", description);
      formData.append("featured_image", featuredImage);
      if(userData?.data?.role == "agent"){
        formData.append("createdBy", userData?.data?._id)
      }
      for (const image of galleryImages) {
        formData.append("gallery_images", image);
      }
      try {
        const res = await axios.post(
          `${API_BASE_URL}/properties/create-property`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.status === 200) {
          setSuccessAlert(true);
          alterValidationMessage("Successfully Added Your Property!");
          setTitle("");
          setAddress("");
          setPrice("");
          setDescription("");
        }
      } catch (error) {
        console.log("Error:", error);
        setSuccessAlert(false);
        alterValidationMessage("Unable to Add New Property");
      }
    }
  };

  const handleFeaturedImageChange = (e) => {
    setFeaturedImage(e.target.files[0]);
  };

  const handleGalleryImageChange = (e) => {
    setGalleryImages(e.target.files);
  };

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="text-center my-5">
            <h1>ADD PROPERTIES</h1>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <input
                  type="text"
                  className={`${styles.loginField} px-4 my-2`}
                  value={title}
                  placeholder="Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  type="text"
                  className={`${styles.loginField} px-4 my-2`}
                  value={address}
                  placeholder="Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
                <input
                  type="number"
                  className={`${styles.loginField} px-4 my-2`}
                  value={price}
                  placeholder="Price"
                  onChange={(e) => setPrice(e.target.value)}
                />
                <input
                  type="text"
                  className={`${styles.loginField} px-4 my-2`}
                  value={description}
                  placeholder="Description"
                  onChange={(e) => setDescription(e.target.value)}
                />

                <div>
                  <label className="my-2">Featured Image:</label>
                  <br />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFeaturedImageChange}
                  />
                </div>

                <div>
                  <label className="my-2">Gallery Images:</label>
                  <br />
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleGalleryImageChange}
                  />
                </div>
                <div className="d-block w-25 mt-3">
                  <button className={`${styles.loginBtn}`} type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}


export default AddProperty;