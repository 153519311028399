import axios from "axios";
import React, { useState } from "react";
import styles from "./comps.module.css";
import { FaUpload } from "react-icons/fa";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import Header from "../../componenets/Header/Header";


function Comps() {

  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);

  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [featuredImage, setFeaturedImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);

  const user = useSelector((state) => state);
  const navigate = useNavigate();

  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // validation
  const formValidation = () => {
    if (
      title &&
      address &&
      price &&
      description &&
      featuredImage &&
      galleryImages
    ) {
      if (galleryImages.length > 10) {
        setSuccessAlert(false);
        alterValidationMessage("You Upload Maximum 10 Images");
      } else {
        return true;
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };

  // Add new Property...
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.isLoggedIn) {
      if (formValidation()) {
        // set property into form data...
        const formData = new FormData();
        formData.append("title", title);
        formData.append("address", address);
        formData.append("price", price);
        formData.append("description", description);
        formData.append("featured_image", featuredImage);
        for (const image of galleryImages) {
          formData.append("gallery_images", image);
        }

        // Post new  property...
        try {
          await axios.post(
            `${API_BASE_URL}/properties/create-property`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setSuccessAlert(true);
          alterValidationMessage("Property Add Successfully!");
        } catch (error) {
          setSuccessAlert(false);
          alterValidationMessage("Unable to Add property, trylater or retry");
        }
      }
    } else {
      // if user not authenticate navigate to login pages.
      navigate("/login");
    }
  };

  // set property featured img value
  const handleFeaturedImageChange = (e) => {
    setFeaturedImage(e.target.files[0]);
  };

  // set property gallery images value
  const handleGalleryImageChange = (e) => {
    setGalleryImages(e.target.files);
  };

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <Header isBlur={false} />

      <div className={styles.body}>
        <div className="text-center my-5">
          <h1>ADD PROPERTIES</h1>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div>
              <input
                type="text"
                className={`${styles.loginField} px-4 my-2`}
                value={title}
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                type="text"
                className={`${styles.loginField} px-4 my-2`}
                value={address}
                placeholder="Address"
                onChange={(e) => setAddress(e.target.value)}
              />
              <input
                type="number"
                className={`${styles.loginField} px-4 my-2`}
                value={price}
                placeholder="Price"
                onChange={(e) => setPrice(e.target.value)}
              />
              <input
                type="text"
                className={`${styles.loginField} px-4 my-2`}
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />

              <div>
                <label
                  className={`${styles.addPropImageBtn} my-2`}
                  htmlFor="featured_image"
                >
                  Featured Image <FaUpload />
                </label>
                <br />
                <input
                  type="file"
                  className="d-none"
                  id="featured_image"
                  accept="image/*"
                  onChange={handleFeaturedImageChange}
                />
              </div>

              <div>
                <label
                  className={`${styles.addPropImageBtn} my-2`}
                  htmlFor="gallery_images"
                >
                  Gallery Image <FaUpload />
                </label>
                <br />
                <input
                  type="file"
                  className="d-none"
                  id="gallery_images"
                  accept="image/*"
                  multiple
                  onChange={handleGalleryImageChange}
                />
              </div>
              <div className="d-block w-25 mt-1">
                <button className={`${styles.loginBtn}`} type="submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Comps;
