import { createContext, useState } from "react";


const myContext = createContext();

export const AppProvider = ({children})=>{

   const [userType, setUserType] = useState("");
   // header 
   const [close, setClose] = useState(true);
   const openSidebar = ()=>{
    setClose(false);
   }
   const closeSidebar = ()=>{
    setClose(true);
   }

   // dashboard

   const [dclose, dsetClose] = useState(false);

   const dopenSidebar = ()=>{
    dsetClose(false);
   }

   const dcloseSidebar = ()=>{
    dsetClose(true);
   }



    return (
       <myContext.Provider 
          value={{
            userType,
            setUserType,
            close,
            openSidebar,
            closeSidebar,
            dclose,
            dopenSidebar,
            dcloseSidebar
          }}
       >
        {children}
       </myContext.Provider>
    );
}

export default myContext;