import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./passReset.module.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { NotificationAlert } from "../componenets/NotificationAlert/NotificationAlert";

export default function Otp() {
  const navigate = useNavigate();
  const [newPasswordFields, setNewPasswordFields] = useState({
    otp: "",
    email: "",
    password: "",
    cpassword: "",
  });


  const handlePasswordsFields = (e) => {
    setNewPasswordFields({
      ...newPasswordFields,
      [e.target.name]: e.target.value,
    });
  };


  const submitNewPassword = async (e) => {
    e.preventDefault();
    if (
      newPasswordFields.otp &&
      newPasswordFields.password &&
      newPasswordFields.cpassword
    ) {
      if (newPasswordFields.password === newPasswordFields.cpassword) {
        try {
          await axios
            .post(`${API_BASE_URL}/auth/reset-password`, {
              email: newPasswordFields.email,
              otpCode: newPasswordFields.otp,
              password: newPasswordFields.password,
            })
            .then((res) => {
              if (res.status === 200) {
                navigate("/login");
              }
            });
        } catch (error) {
          NotificationAlert(error?.response?.data?.message, "failed")
          console.log(error, "unable to set new password");
        }
      } else {
        alert("Not Match Password or Confirm Password");
      }
    } else {
      alert("All field required!");
    }
  };


  return (
    <>
      <div className={`${styles.body}`}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <div className={`${styles.forgot_passHeader}`}>
                  <h1 className="text-center text-white fw-bold">
                    Reset Password
                  </h1>
                </div>

                <div className={`${styles.forgot_passFormContainer} `}>
                  <Form className="text-center ">
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.forgot_passField}`}
                        type="number"
                        name="otp"
                        value={newPasswordFields.otp}
                        onChange={handlePasswordsFields}
                        placeholder="Enter OTP"
                      />
                    </Form.Group>
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.forgot_passField}`}
                        type="email"
                        name="email"
                        value={newPasswordFields.email}
                        onChange={handlePasswordsFields}
                        placeholder="Enter Email"
                      />
                    </Form.Group>
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.forgot_passField}`}
                        type="password"
                        name="password"
                        value={newPasswordFields.password}
                        onChange={handlePasswordsFields}
                        placeholder="New Password"
                      />
                    </Form.Group>
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.forgot_passField}`}
                        type="password"
                        name="cpassword"
                        value={newPasswordFields.cpassword}
                        onChange={handlePasswordsFields}
                        placeholder="Confirm Password"
                      />
                    </Form.Group>

                    <Button
                      variant="primary"
                      className={`${styles.forgot_passBtn}`}
                      type="submit"
                      onClick={submitNewPassword}
                    >
                      SUBMIT
                    </Button>
                  </Form>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <h5 className="text-white">OR</h5>
              </div>

              <div className="d-flex mt-4">
                <Link to="#">
                  <img
                    src="./assets/images/authAssets/facebook.png"
                    className="px-1"
                    alt="facebook"
                  />
                </Link>
                <Link to="#">
                  <img
                    src="./assets/images/authAssets/twitter.png"
                    className="px-1"
                    alt="twitter"
                  />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
