import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./bids.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { useSelector } from "react-redux";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";


function AllBids() {

  
  const { dclose } = useContext(myContext);
  const [allBids, setAllBids] = useState([]);
  const userData = useSelector((state) => state.data);
  const [loader, setLoader] = useState(false);



  const getAllBids = () => {
    setLoader(true);
    axios
    .get(`${API_BASE_URL}/properties/all-properties`)
    .then((res) => {
      setAllBids(res.data);
      setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  
  useEffect(() => {
    getAllBids();
  }, []);

  
  const deleteBids = ( bidID ,id ) => {
    
    axios
      .delete(`${API_BASE_URL}/properties/property/${id}/remove-bid/${bidID}`)
      .then((res) => {
        if (res.status === 200) {
          NotificationAlert("Delete Successfully", "success");
          getAllBids();
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationAlert("Failed to delete", "failed");
      });
  };

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes/>
          </div>

          {/* User Listing  */}
          <div className="row">
            <h1 className="text-center py-4">All Bid's</h1>
            <div className={`${styles.table_wrapper}`}>
            <table className={`${styles.table_container}`}>
            <thead className={`${styles.table_header}`}>
              <tr>
              <th  className="text-center">#</th>
              <th>Property Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Time And Date</th>
                <th className="text-center">Deletes</th>
              </tr>
              </thead>
              <tbody  className={`${styles.table_body}`}>
              {loader ?  <tr><td colSpan="7" className="mx-auto text-center">Loading...</td></tr>:
              userData?.role === "team associates" ? 
               allBids?.filter(
                (item) => item?.assignedTo[0] === userData?._id
              )?.map((item) => {
                return (
                  <>
                    {item.bids.map((bids, i) => {
                      return (
                        <tr key={bids._id}>
                           <td  className="text-center">{i+1}</td>
                        <td>{item.title}</td>
                          <td>{bids.username}</td>
                          <td>{bids.email}</td>
                          <td>{bids.bids}</td>
                          <td>{bids.created_at}</td>
                          <td className="text-center">
                            <Button variant="dark" className={styles.table_btns} onClick={() => deleteBids(bids._id, item._id)}>
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                );
              })
              :
              allBids.map((item) => {
                return (
                  <>
                    {item.bids.map((bids, i) => {
                      return (
                        <tr key={bids._id}>
                           <td  className="text-center">{i+1}</td>
                        <td>{item.title}</td>
                          <td>{bids.username}</td>
                          <td>{bids.email}</td>
                          <td>{bids.bids}</td>
                          <td>{bids.created_at}</td>
                          <td className="text-center">
                            <Button disabled={userData?.role == "agent" ? true:false} variant="dark" className={styles.table_btns} onClick={() => deleteBids(bids._id, item._id)}>
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                );
              })
              }
             </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllBids;
