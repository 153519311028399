import axios from "axios";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../../config";
import myContext from "../../../context/context";
import styles from "./listedproperties.module.css";
import React, { useEffect, useState } from "react";
import TopNav from "../../components/dashbaordHeader/TopNav";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";


function ListedProperties() {

  const { dclose } = useContext(myContext);
  const [loader, setLoader] = useState(false);
  const [listedProperties, setListedProperties] = useState([]);
  const userData = useSelector((state) => state.data);
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false)
  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  const getlistedProperties = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/properties/all-properties`)
      .then((res) => {
        setListedProperties(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    getlistedProperties();
  }, []);
  const handleApproval = async (approved, id) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/properties/update-approval/${id}`, { approved })
      if (response?.data?.success) {
        getlistedProperties()
        setSuccessAlert(true)
        alterValidationMessage(response?.data?.message)
      }
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="row">
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Property Title</th>
                    <th>Address</th>
                    <th>Price</th>
                    {userData?.role === "team associates" ? "" : <th>Action</th>}
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ?
                    <tr><td colSpan="4" className="mx-auto text-center">Loading...</td></tr>
                    :
                    userData?.role === "team associates"
                      ? listedProperties
                        ?.filter(
                          (item) => item?.assignedTo[0] === userData?._id
                        )
                        ?.map((item, i) => {
                          return (
                            <>
                              <tr key={item._id}>
                                <td className="text-center">{i + 1}</td>
                                <td>{item.title}</td>
                                <td>{item.address}</td>
                                <td>${item.price}</td>
                              </tr>
                            </>
                          );
                        })
                      :
                      userData?.role === "agent" ?
                        <>
                          {
                            listedProperties
                              ?.filter(
                                (item) => item?.createdBy === userData?._id
                              )
                              ?.map((item, i) => {
                                return (
                                  <>
                                    <tr key={item._id}>
                                      <td className="text-center">{i + 1}</td>
                                      <td>{item.title}</td>
                                      <td>{item.address}</td>
                                      <td>${item.price}</td>
                                      <td className="text-center">
                                        <button disabled={userData?.role == "agent" ? true : false} style={{ cursor: "pointer" }} onClick={() => item?.approved ? handleApproval(false, item?._id) : handleApproval(true, item?._id)} className={item?.approved ? `btn btn-success` : `${styles.table_btns} btn`}  >{item?.approved ? 'Approved' : "Not Approved"}</button>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                          }
                        </>
                        : listedProperties.map((item, i) => {
                          return (
                            <>
                              <tr key={item._id}>
                                <td className="text-center">{i + 1}</td>
                                <td>{item.title}</td>
                                <td>{item.address}</td>
                                <td>$ {item.price}</td>
                                <td className="text-center">
                                  <button disabled={userData?.role == "agent" ? true : false} style={{ cursor: "pointer" }} onClick={() => item?.approved ? handleApproval(false, item?._id) : handleApproval(true, item?._id)} className={item?.approved ? `btn btn-success` : `${styles.table_btns} btn`}  >{item?.approved ? 'Approved' : "Not Approved"}</button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListedProperties;
