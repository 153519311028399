import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./bookingcompletelist.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { useSelector } from "react-redux";


function BookingCompleteList() {


  const { dclose } = useContext(myContext);
  const [allBookings, setAllBookings] = useState([]);
  const [loader, setLoader] = useState(false);
  const userData = useSelector((state) => state?.data);

  const getAllBookings = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/bookings`)
      .then((res) => {
        setAllBookings(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    getAllBookings();
  }, []);
  const assignedBookingAgent = allBookings?.filter((item) => item?.propertyID?.assignedAgent[0] == userData?._id && item?.bookingStatus == "Completed")
  console.log(assignedBookingAgent)
  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          {/* User Listing  */}
          <div className="row">
            <h1 className="text-center py-4">Update Booking Status</h1>
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th>Property Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th className="text-center">Booking Date</th>
                    <th className="text-center">Booking Time</th>
                    <th className="text-center">Booking Status</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ?
                    <tr>
                      <td colSpan="7" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                    : userData?.role == "agent" ?
                      assignedBookingAgent?.map((item) => {
                          return (
                            <>
                              <tr key={item._id}>
                                <td>{item.propertyTitle}</td>
                                <td>{item.username}</td>
                                <td>{item.email}</td>
                                <td className="text-center">{item.bookingDate}</td>
                                <td className="text-center">{item.bookingTime}</td>
                                <td className="text-center">
                                  {item.bookingStatus}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      :
                      allBookings
                        ?.filter((item) => item.bookingStatus === "Completed")
                        .map((item) => {
                          return (
                            <>
                              <tr key={item._id}>
                                <td>{item.propertyTitle}</td>
                                <td>{item.username}</td>
                                <td>{item.email}</td>
                                <td className="text-center">{item.bookingDate}</td>
                                <td className="text-center">{item.bookingTime}</td>
                                <td className="text-center">
                                  {item.bookingStatus}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default BookingCompleteList;