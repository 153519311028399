
const Probate = () => {

 

  return (
    <div>
            <h1>To be discussed.</h1> 

    </div>
  )
}

export default Probate
