import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";


const ModalUnblockUser = ({ setShowModal, blockedUser}) => {

  const [giveRole, setGiveRole] = useState(blockedUser?.role);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.patch(`${API_BASE_URL}/all-users/update-user/${blockedUser?._id}`, { role: giveRole });
      if (res.status === 200) {
        NotificationAlert("Role Update Successfully!", "success");
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
      NotificationAlert("Role Update Successfully!", 'failed')
    }
  }


  return (
    <>
      <div className="modal_wrapper text-center">
      <div className="modal-form">
        <span className="close-modal" onClick={() => setShowModal(false)}>X</span>
        <div className="form-input-container">
          <label>Role:</label>
          <br />
          <select onChange={(e) => setGiveRole(e.target.value)}>
              <option value="user">All Types</option>
              <option value="user">Users</option>
              <option value="block">Block Users</option>
              <option value="manager">Managers</option>
              <option value="attorney">Attornies</option>
              <option value="personal rep">Persoanl Reps</option>
              <option value="team associates">Team Associate</option>
              <option value="agent">Agents</option>
          </select>
        </div>

        <div >
          <button className="modal-btn" onClick={handleSubmit}>Submit</button>
        </div>

      </div>
      </div>
    </>
  );
};

export default ModalUnblockUser;
