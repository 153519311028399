import { FaMapMarkerAlt, FaAngleDoubleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config";


const Footer = () => {

  const [email, setEmail] = useState("");
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);


  // alter validation error 
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // validation
  const formValidation = () => {
    if (email) {
      var emailRegex =/^\w+[\w.-]*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

      if (emailRegex.test(email)) {
          return true;
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Email is not valid");
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("email required");
    }
    return false;
  };
  
  const handleNewsletter = async () => {
    if (formValidation()) {
      try {
        await axios.post(`${API_BASE_URL}/newsletter/`, { email });
        setSuccessAlert(true);
        alterValidationMessage("Thanks for subscribe!")
        setEmail("")
      } catch (error) {
        console.log(error);
      }
    }
  };


  return (
    <>
    {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert   d-flex justify-content-between"
              : "alert alert-danger validation-alert  d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <div className="container-fluid pt-5" id="footer">
        <div className="row mt-5">
          <div className="col-lg-6 d-flex justify-content-center">
            <h1 className="ms-5 text-lg-start text-center">
              Don't miss our updates. <br /> Subscribe us for more info.
            </h1>
          </div>
          <div className="col-lg-6  d-flex justify-content-center pt-3">
            <div className={`${styles.email_box}`}>
              <input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
              />
              <button onClick={handleNewsletter}>Get Started</button>
            </div>
          </div>
          <div className="col-12 py-5 mt-5 text-center">
            <figure>
              <img
                src="./assets/images/home/realstate.png"
                alt=""
                className={styles.footer_logo}
              />
            </figure>
            <span className={styles.footer_location}>
              <FaMapMarkerAlt className={styles.footer_location_icon} />
              Find our location
            </span>
          </div>
          <div className={`col-12 pb-5 pt-3 text-center`}>
            <div className={`${styles.footer_nav_container}`}>
              <span>
                <Link className={`mx-3 ${styles.footer_nav}`}>FAQ'S</Link> |
              </span>
              <span>
                <Link className={`mx-3 ${styles.footer_nav}`}>TERM OF USE</Link>{" "}
                |
              </span>
              <span>
                <Link className={`mx-3 ${styles.footer_nav}`}>
                  PRIVATE POLICY
                </Link>{" "}
                |
              </span>
              <span>
                <Link className={`mx-3 ${styles.footer_nav}`}>COOKIES</Link>{" "}
              </span>
            </div>
            <div className={`${styles.binary_chemist_mark}`}>
              <p>
                Website by <span ><Link to="#" target="blank" className="text-light">The Top Notch Designs</Link></span>
              </p>
            </div>
          </div>
          <div className="col-12 text-center pb-5">
            <a href="#header">
              <button className={styles.back_top}>
                Back To <span className="text-light">Top</span>{" "}
                <FaAngleDoubleUp />
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
