import Header from "../../componenets/Header/Header";
import styles from "./singleproperty.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import BidingModal from "../../componenets/BidingModal/BidingModal";
import Footer from "../../componenets/Footer/Footer";
import axios from "axios";
import GalleryViewDynamic from "../../componenets/GalleryViewDynamic/GalleryViewDynamic";
import { useSelector } from "react-redux";
import BookingModal from "../../componenets/bookingModal/BookingModal";
import DeleteBookingModal from "../../componenets/DeleteBookingModal/DeleteBookingModal";
import CommentModal from "../../componenets/CommentModal/CommentsModal";
import PurchaseModal from "../../componenets/PurchaseModal/PurchaseModal";
import Moment from "moment";

const SingleProperty = () => {


  // get property id and initialize navigator...
  const { id } = useParams();
  const navigate = useNavigate();

  // get login user....
  const userData = useSelector((state) => state);

  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  // all states of single property pages...
  const [bidingModal, setBidingModal] = useState(false);
  const [bookingModal, setBookingModal] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [propertyStatus, setPropertyStatus] = useState();
  const [singleProperty, setSingleProperty] = useState({});
  const [showGallery, setShowGallery] = useState(false);
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState(userData);
  const [allBookings, setAllBookings] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteBookingData, setDeleteBookingData] = useState("");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [updateCommentData, setUpdateCommentData] = useState("");
  const [comments, setComments] = useState();
  const [myres, setMyres] = useState();
  const [commentdec, setCommentdec] = useState({
    comment: "",
    user_id: user?.data?._id,
    email: user?.data?.email,
    username: user?.data?.username,
  });

  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // get single property ...
  function getPropertyData() {
    try {
      axios
        .get(`${API_BASE_URL}/properties/property-details/${id}`)
        .then((res) => {
          setSingleProperty(res.data);
          setComments(res?.data?.comments);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err, "single property error!");
        });
    } catch (error) {
      console.log(error);
    }
  }

  /////////////////////////
  ////// COMMENTS.... /////
  /////////////////////////

  // handle comment input...
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setCommentdec((prevState) => ({ ...prevState, [name]: value }));
  };

  // post new comments...
  const postPropertyComments = async (e) => {
    e.preventDefault();
    // authorized user, then post comment
    if (user.isLoggedIn) {
      if (commentdec.comment) {
        try {
          axios
            .post(
              `${API_BASE_URL}/properties/property/${id}/comment`,
              commentdec
            )
            .then((res) => {
              setCommentdec((prevState) => ({ ...prevState, comment: "" }));
            });
        } catch (err) {
          console.log(err, "failed to post comment");
          setSuccessAlert(false);
          alterValidationMessage(
            "Failed to post your comment, try later or retry!"
          );
        }
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Plz Write your comment!");
      }
    } else {
      navigate("/login");
    }
  };

  // update comment, and set modal
  const handleUpdateComment = (data) => {
    setUpdateCommentData(data);
    setShowCommentModal(true);
  };

  // delete comment
  const deleteComment = async (commentId) => {
    try {
      await axios.delete(
        `${API_BASE_URL}/properties/property/${singleProperty._id}/remove-comment/${commentId}`
      );
      getPropertyData();
    } catch (err) {
      console.log(err);
    }
  };

  ////////////////////////////
  /////// BOOKINGS... //////
  /////////////////////////

  // get all bookings...
  const getBooking = () => {
    try {
      axios
        .get(`${API_BASE_URL}/bookings`)
        .then((res) => {
          setAllBookings(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //  delete booking...
  const handleDeleteBooking = (data) => {
    setDeleteBookingData(data);
    setShowDeleteModal(true);
  };

  // get single property and its bookings..
  useEffect(() => {
    setUser(userData);
    getPropertyData();
    getBooking();
  }, [id, userData, commentdec, singleProperty._id, myres]);

  // Property Images Carousel Settings...
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoPlay: true,
    autoPlaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const bookingModalAuth = () => {
    userData.isLoggedIn ? setBookingModal(true) : navigate("/login");
  };

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      {showGallery ? (
        <GalleryViewDynamic
          showGallery={showGallery}
          setShowGallery={setShowGallery}
          imgLinks={singleProperty?.gallery_images}
        />
      ) : (
        ""
      )}

      <Header />
      <div className="backgound_lines_image">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6">
              {loader ? (
                <div className="text-center mt-5">
                  <div className="spinner-border " role="status"></div>
                </div>
              ) : (
                <>
                  {/* Single Property Gallery */}
                  {/* xxxxxxxxxxxxx */}
                  <Slider {...settings} className={`${styles.parent_arrow}`}>
                    {singleProperty?.gallery_images?.map((link, i) => (
                      <div className={`${styles.galleryimg_box}  p-0 `} key={i}>
                        <img
                          src={`${API_BASE_URL}/${link}`}
                          className={`${styles.galleryimg}`}
                          alt="galleryimage"
                          onClick={() => setShowGallery(true)}
                        />
                      </div>
                    ))}
                  </Slider>

                  {/* xxxxxxxxxxxxx */}
                  {/* property description */}
                  <div
                    className={`${styles.single_property_content} my-3 ps-4 pt-2`}
                  >
                    <span className={`${styles.status}`}>
                      {singleProperty?.status}
                    </span>
                    <h2 className="mt-3">
                      <span className={styles.line}>
                        {singleProperty?.title}
                      </span>
                    </h2>
                    <span className={`${styles.address}`}>
                      {singleProperty?.address}
                    </span>
                    <p className={`${styles.decs} mt-3`}>
                      {singleProperty?.description}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-6">
              {/* booking container map booking. */}
              <div className={`${styles.biding_container} mx-auto mb-3 p-3`}>
                <h2>$ {singleProperty?.price}</h2>
                <h3>Your Bookings</h3>
                <div className={`${styles.biding_list}`}>
                  {allBookings && allBookings.length > 0
                    ? allBookings
                        ?.filter(
                          (item) =>
                            item.email === user?.data?.email &&
                            item.propertyTitle === singleProperty?.title
                        )
                        ?.map((data) => (
                          <div
                            key={data?._id}
                            className={`${styles.biding_single_list} me-2 py-2 d-flex justify-content-between`}
                          >
                            <span>{data?.bookingDate}</span>
                            <span>$ {data?.bookingTime}</span>
                            <span
                              className="fw-bold text-danger"
                              onClick={() => handleDeleteBooking(data)}
                            >
                              Delete
                            </span>
                          </div>
                        ))
                    : "Book Your Appointment Today!"}
                </div>
                <div className={`${styles.bide_now} text-center py-3`}>
                  <button onClick={bookingModalAuth}>Book Now</button>
                </div>
              </div>
              {showDeleteModal ? (
                <DeleteBookingModal
                  show={showDeleteModal}
                  onHide={() => setShowDeleteModal(false)}
                  data={deleteBookingData}
                  setMyres={setMyres}
                />
              ) : (
                ""
              )}
              {bookingModal ? (
                <BookingModal
                  id={id}
                  singleProperty={singleProperty.title}
                  bookingModal={bookingModal}
                  setBookingModal={setBookingModal}
                  setMyres={setMyres}
                />
              ) : (
                ""
              )}

              {/* biding container map biding */}
              <div className={`${styles.biding_container} mx-auto mb-3 p-3`}>
                <h2>$ {singleProperty?.price}</h2>
                <h3>Biding's</h3>
                <div className={`${styles.biding_list}`}>
                  {singleProperty?.bids
                    ?.sort((a, b) => b?.bids - a?.bids)
                    ?.map((data, i) => (
                      <div
                        key={i}
                        className={`${styles.biding_single_list} me-2 py-2 d-flex justify-content-between`}
                      >
                        <span>{data?.username}</span>
                        <span>$ {data?.bids}</span>
                      </div>
                    ))}
                </div>
                <div className={`${styles.bide_now} text-center py-3`}>
                  <button onClick={() => setBidingModal(true)}>Bid Now</button>
                </div>
              </div>
              {bidingModal ? (
                <BidingModal
                  id={id}
                  bidingModal={bidingModal}
                  setBidingModal={setBidingModal}
                  setMyres={setMyres}
                />
              ) : (
                ""
              )}
              {/* propery map */}
              <div className={`${styles.biding_container} mx-auto mb-3 p-3`}>
                <h2>$ {singleProperty?.price}</h2>
                <h3>Purchase</h3>

                <div className={`${styles.propery_map}`}></div>
                <div className={`${styles.bide_now} text-center py-3`}>
                  <button onClick={() => setPurchaseModal(true)}>
                    Purchase Now
                  </button>
                </div>
              </div>
              {purchaseModal ? (
                <PurchaseModal
                  id={id}
                  propertyTitle={singleProperty.title}
                  setPurchaseModal={setPurchaseModal}
                  status={propertyStatus}
                  setMyres={setMyres}
                  setPropertyStatus={setPropertyStatus}
                />
              ) : (
                ""
              )}
            </div>
            <div className="col-12 mt-5 py-5">
              <h2 className="ps-4 my-5">
                <span className={styles.line}>Comment's</span>
              </h2>

              {/* new comments */}
              <div className={`${styles.new_comment}`}>
                <div className={`${styles.comment_field} my-3`}>
                  <input
                    type="text"
                    value={commentdec.comment}
                    name="comment"
                    onChange={handleInputs}
                    placeholder="Enter Your Comments"
                  />
                </div>
                <div className={`${styles.post_btn_div} text-end`}>
                  <button
                    onClick={postPropertyComments}
                    className={`${styles.post_btn}`}
                  >
                    Post
                  </button>
                </div>
              </div>

              {/* published comments */}

              {comments?.map((commentz, i) => {
                return (
                  <div key={i} className={`${styles.property_comments}`}>
                    <div className={`${styles.comments_box}  my-5`}>
                      <div className={`${styles.user_comment}`}>
                        <figure>
                          <img
                            src={`${API_BASE_URL}/uploads/user_profile.png`}
                            className={`${styles.user_img}`}
                            alt=""
                          />
                        </figure>
                        <span className={`${styles.user_name} ms-3`}>
                          {commentz.username}
                        </span>
                      </div>
                      <div className={`${styles.comment} py-4`}>
                        <p>{commentz.comment}</p>
                        <span className={styles.published_date}>
                          Published at{" "}
                          {Moment(commentz.created_at).format("MM/DD/YYYY")}
                        </span>

                        {user.isLoggedIn &&
                        commentz?.email === user?.data?.email ? (
                          <div>
                            <button
                              className={`${styles.post_btn}`}
                              onClick={() => handleUpdateComment(commentz)}
                            >
                              Edit
                            </button>
                            <button
                              className={`${styles.post_btn}`}
                              onClick={() => deleteComment(commentz._id)}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {showCommentModal ? (
                <CommentModal
                  show={showCommentModal}
                  onHide={() => setShowCommentModal(false)}
                  data={updateCommentData}
                  setComments={setComments}
                  id={id}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleProperty;

// Carousel Buttons Components...
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div>
      <MdOutlineArrowForwardIos
        className={`${className} ${styles.arrowStyle} `}
        style={{ color: "white", bottom: "0", right: "0%", zIndex: 100 }}
        onClick={onClick}
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div>
      <MdOutlineArrowBackIosNew
        className={`${className} ${styles.arrowStyle} `}
        style={{
          fontSize: "100px",
          color: "white",
          bottom: "0",
          left: "0%",
          zIndex: 100,
        }}
        onClick={onClick}
      />
    </div>
  );
};
