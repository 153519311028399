import React from 'react'

const CourtConfirmation = () => {
  return (
    <div>
     <h1>To be discussed.</h1> 
    </div>
  )
}

export default CourtConfirmation
