import axios from "axios";
import Slider from "react-slick";
import styles from "./sale.module.css";
import { API_BASE_URL } from "../../config";
import React, { useState, useEffect } from "react";
import Header from "../../componenets/Header/Header";
import Footer from "../../componenets/Footer/Footer";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";

const Sale = () => {

  
  const [property, setProperty] = useState([]);
  const [loading, setLoading] = useState(true);
  const [propertyLimit, setPropertyLimit] = useState(4);
  const [loadmore, setLoadMore] = useState(false);
  const [search, setSearch] = useState("");

  // get all properties...
  const getAllProperties = async () => {
    try {
      await axios
        .get(`${API_BASE_URL}/properties/all-properties`)
        .then((res) => {
          const soldProperties = res.data.filter(
            (data) => data.status === "sold"
          );
          setProperty(soldProperties);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // load more properties....
  const loadMoreProperties = () => {
    setLoadMore(true);
    setTimeout(() => {
      setPropertyLimit(propertyLimit + 4);
      setLoadMore(false);
    }, 500);
  };
  useEffect(() => {
    getAllProperties();
  }, []);

  // slick slider settings.

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoPlay: true,
    autoPlaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <div className="backgound_lines_image">
        <section className="container">
          <h1 className={`${styles.offer_heading} text-center my-5`}>
            <span className={styles.underline}>SOLD PROPERTY</span>
          </h1>
          <div className="text-center">
            <div className={styles.product_filtration_search}>
              <img alt="aa" src="./assets/images/property/search-icon.png" />
              <input
                type="text"
                placeholder="Search Property"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>



          <div className="row">
            <div className="col">
              {/* set Loader.. */}
              {loading ? (
                <div className="text-center mt-5">
                  <div className="spinner-border " role="status"></div>
                </div>
              ) : // display all sold property after filter....
              property && property.length > 0 ? (
                property
                  ?.filter((data) => data.status === "sold")
                  ?.filter((item) =>
                    item?.title.toLowerCase().includes(search.toLowerCase())
                  )
                  ?.slice(0, propertyLimit)
                  .map((data) => (
                    // new box...
                    <div className={`${styles.property_container} row my-5`}>
                      <div className="col-lg-6">
                        <Slider
                          {...settings}
                          className={`${styles.parent_arrow}`}
                        >
                          {data?.gallery_images?.map((link, i) => (
                            <div
                              className={`${styles.galleryimg_box}  p-0 `}
                              key={i}
                            >
                              <img
                                src={`${API_BASE_URL}/${link}`}
                                className={`${styles.galleryimg}`}
                                alt="galleryimage"
                              />
                            </div>
                          ))}
                        </Slider>
                      </div>
                      <div className="col-lg-6">
                        {/* property description */}
                        <div
                          className={`${styles.single_property_content} my-3 ps-4 pt-2`}
                        >
                          <span className={`${styles.status}`}>
                            {data?.status}
                          </span>
                          <h2 className="mt-3">
                            <span className={styles.line}>{data?.title}</span>
                          </h2>
                          <span className={`${styles.address}`}>
                            {data?.address}
                          </span>
                          <p className={`${styles.decs} mt-3`}>
                            {data?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <h1 className="text-center text-sky">No any sold property!</h1>
              )}

              {/* xxxxxxxx */}
              {!loading &&
                (loadmore ? (
                  <h2 className="text-light text-center">loading...</h2>
                ) : propertyLimit >= property.length ? (
                  ""
                ) : (
                  <div className="text-center">
                    <button
                      className="loadmore-btn"
                      onClick={loadMoreProperties}
                    >
                      Load More
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Sale;

// Slider Arrows

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div>
      <MdOutlineArrowForwardIos
        className={`${className} ${styles.arrowStyle} `}
        style={{ color: "white", bottom: "0", right: "0%", zIndex: 100 }}
        onClick={onClick}
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div>
      <MdOutlineArrowBackIosNew
        className={`${className} ${styles.arrowStyle} `}
        style={{
          fontSize: "100px",
          color: "white",
          bottom: "0",
          left: "0%",
          zIndex: 100,
        }}
        onClick={onClick}
      />
    </div>
  );
};
