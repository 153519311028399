import styles from "./galleryviewdynamic.module.css";
import Slider from "react-slick";
import { API_BASE_URL } from "../../config";

const GalleryViewDynamic = ({ showGallery, setShowGallery, imgLinks }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoPlay: true,
    autoPlaySpeed: 3000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        className={
          showGallery
            ? `${styles.galleryview_wrapper} container-fluid`
            : `${styles.galleryview_wrapper_hide} container-fluid`
        }
      >
        <span className={styles.close} onClick={() => setShowGallery(false)}>
          X
        </span>

        <div className="row">
          <div className="col-10">
            <Slider {...settings} className={`${styles.parent_arrow}`}>
              {imgLinks?.map((link, i) => (
                <div className={`${styles.galleryimg_box}  p-0 `} key={i}>
                  <img
                    src={`${API_BASE_URL}/${link}`}
                    className={`${styles.galleryimg}`}
                    alt="galleryimage"
                    onClick={() => setShowGallery(true)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryViewDynamic;
