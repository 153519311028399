import styles from '../../dashboardpages/Dashboardmain/dashboard.module.css';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';


function LineAreaChart({property}) {

  const [propertyBids,setPropertyBids] = useState([]);
  const [loader, setLoader] = useState(false);

//  Get Property Id to Show their Bids charts...
  const handlePropertyBid = (e)=> {
    // set property id
    const propertyId = e.target.value;
    setLoader(true);
    
    try {
      axios.get(`${API_BASE_URL}/properties/property/${propertyId}/get-bids`)
      .then(res => {
        setPropertyBids(res.data);
        setLoader(false);
      })
      .catch(err => console.log(err))

     } catch (error) {
      console.log(error, "Unalbe to handle get a bids...");
    }
  }

  return (
    <>
        <div className="col-lg-6 mx-auto mt-lg-0 mt-2">
        <div className={`${styles.main_chart_container} mx-auto`}>
            <h6 className="ms-2 mb-0">CHECK PROPERTY BIDS</h6>
            <div className={`${styles.chart_nav}`}>
            <div className='my-2'>
          <select className={styles.select_box}  defaultValue={"DEFAULT"} onChange={handlePropertyBid}>   
            <option value="DEFAULT" disabled >Select Property</option>
            {
              property && property.length >0 ? 
               property?.map(item=>(
                 <option key={item?._id} value={item?._id}>{item?.title}</option>
               ))
              :""
            }
          </select>



        </div>
          </div>
            <div style={{ width: "100%", height: "80%" }}>
              {
                propertyBids && propertyBids.length>0?
                  loader? "loading...":
                  <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={500}
                    height={300}
                    data={propertyBids}
                    syncId="anyId"
                    margin={{
                      top: 10,
                      right: 20,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid height={10} strokeDasharray="2 2" />
                    <XAxis dataKey="created_at" />
                    <YAxis  />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="bids"
                      stroke="#1976D2"
                      fill="#1C61A9"
                    />
                  </AreaChart>
                </ResponsiveContainer>
                 : 
                <h4 className={styles.nobids_heading}>This property have no bids</h4>
              }
            </div>
          </div>
        </div>
    </>
  );
}



export default LineAreaChart;
