import React from "react";
import { PieChart, Cell, Pie } from "recharts";
import styles from '../../dashboardpages/Dashboardmain/dashboard.module.css';


const COLORS = ["#45A321", "#CD4D4D"];

const ChartPie = ({property}) => {

   const soldProperties = property.filter(item => item.status === "sold");
   const unsoldProperties = property.filter(item => item.status === "unsold");
   
   const dataPie = [
    {
      name: "sold",
      value: soldProperties.length
    },
    {
      name: "unsold",
      value: unsoldProperties.length
    }
   ]

  return (
    <>
      <div className="col-lg-6 mx-auto mt-lg-0 mt-4">
        <div className={`${styles.main_chart_container} mx-auto`}>
          <h6 className="ms-2">SOLD AND UNSOLD PROPERTIES</h6>
         
          <div
            style={{ width: "100%", height: "80%" }}
            className="d-flex position-relative align-items-center justify-content-center"
          >
            <PieChart width={300} height={300}>
              <Pie
                data={dataPie}      
                cx={150}
                cy={150}
                innerRadius={80}
                outerRadius={100}
                fill="#8884d8"
                stroke="0"
                paddingAngle={0}
                dataKey="value"
              >
                {dataPie.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    dataKey={entry.name}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className={`${styles.chart_field} d-flex flex-column`}>
              <span>
                <i className={`${styles.chart_flied_icon2} fa fa-stop`}  aria-hidden="true"></i>{" "}
                SOLD
              </span>
              <span>
                <i className={`${styles.chart_flied_icon3} fa fa-stop`}  aria-hidden="true"></i>{" "}
                UNSOLD
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartPie;
