import { useEffect } from "react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


const ProtectedPage = ({ Page }) => {


  const [userType, setUserType] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    const user = localStorage.getItem("persist:user");
    const userData = JSON.parse(user);
    const userRole = JSON.parse(userData?.data);
    setUserType(userRole?.role);
  }, []);

  
  if (
    userType !== "manager" &&
    userType !== "admin" &&
    userType !== "user" &&
    userType !== "personal rep" &&
    userType !== "agent" &&
    userType !== "attorney" &&
    userType !== "team associates" &&
    userType !== "super admin"
  ) {
    navigate("/");
    return;
  } else {
    return (
      <>
        <Page />
      </>
    );
  }
};

export default ProtectedPage;

