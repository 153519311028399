import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { Provider } from "react-redux";
import { persistor, store } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import BookingExpire from "./dashboard/components/Bookingexpire/BookingExpire";
import { AppProvider } from "./context/context";




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AppProvider>
        <React.StrictMode>
          <App />
          <BookingExpire />
        </React.StrictMode>
      </AppProvider>
    </PersistGate>
  </Provider>
);
