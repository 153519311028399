import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./listeduser.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";

function ListedUsers() {
  const { dclose } = useContext(myContext);
  const [allUsers, setAllUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [loader, setLoader] = useState(false);

  const getListedUsers = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/all-users`)
      .then((res) => {
        setAllUsers(res.data);
        setFilterUsers(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getListedUsers();
  }, []);

  const filterUserByType = (e) => {
    if (e.target.value === "all") {
      setFilterUsers(allUsers);
    } else {
      const filterUsers = allUsers.filter((user) => {
        return user.role === e.target.value;
      });
      setFilterUsers(filterUsers);
    }
  };

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>
          <div className="row">
            <div className="mt-5">
              <select
                name="usertype"
                className={`${styles.select_roles_field}`}
                onChange={filterUserByType}
              >
                <option value="all">All Types</option>
                <option value="user">Users</option>
                <option value="block">Block Users</option>
                <option value="manager">Managers</option>
                <option value="attorney">Attornies</option>
                <option value="personal rep">Persoanl Reps</option>
                <option value="team associates">Team Associate</option>
                <option value="agent">Agents</option>
              </select>
            </div>
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="4" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    filterUsers.map((item, i) => {
                      return (
                        <>
                          <tr key={item.id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{item.username}</td>
                            <td>{item.email}</td>
                            <td>{item.role}</td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListedUsers;
