import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "../userHistory/userhistory.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";




function AdminList() {


  const { dclose } = useContext(myContext);
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(false);


  const getAllUsers = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/all-users`)
      .then((res) => {
        setAllUsers(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  useEffect(() => {
    getAllUsers();
  }, []);


  const deleteListing = ( id ) => {
    axios
      .delete(`${API_BASE_URL}/all-users/delete-user/${id}`)
      .then((res) => {
        if (res.status === 200) {
          NotificationAlert("Deleted Successfully", "success");
          getAllUsers()
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationAlert("Failed to delete", "failed");
      });
  };




  const filteredAdmin = allUsers.filter((item) => {
    return item.role === "admin";
  });

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
           <CountBoxes/>
          </div>

        
          {/* Admin Listing  */}
          <div className="row">
            <h1 className="text-center py-4">
              Admin Listings
            </h1>
            <div className={`${styles.table_wrapper}`}>
            <table className={`${styles.table_container}`}>
            <thead className={`${styles.table_header}`}>
              <tr>
                <th  className="text-center">#</th>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th className="text-center">Deletes</th>
              </tr>
              </thead>
              <tbody  className={`${styles.table_body}`}>
              {loader? <tr><td colSpan="5" className="mx-auto text-center">Loading...</td></tr> : 
              filteredAdmin.map((item, i) => {
                return (
                  <>
                    <tr key={item._id}>
                      <td  className="text-center">{i+1}</td>
                      <td>{item.username}</td>
                      <td>{item.email}</td>
                      <td>{item.role}</td>
                      <td className="text-center">
                        <Button variant="dark" className={styles.table_btns} onClick={()=>deleteListing(item?._id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  </>
                );
              })}
              </tbody>

            </table>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}




export default AdminList;


