import axios from "axios";
import { useSelector } from "react-redux";
import styles from "./allbookings.module.css";
import { API_BASE_URL } from "../../../config";
import myContext from "../../../context/context";
import TopNav from "../../components/dashbaordHeader/TopNav";
import React, { useEffect, useState, useContext } from "react";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";

function AssignedBooking() {

  const { dclose } = useContext(myContext);
  const [allBookings, setAllBookings] = useState([]);
  const userData = useSelector((state) => state.data);
  const [loader, setLoader] = useState(false);


  const getAllBookings = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/properties/assigned-properties/booking-details/${userData?._id}`)
      .then((res) => {
        console.log(res.data);
        setAllBookings(res.data.bookings);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    getAllBookings();
  }, []);



  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          {/* User Listing  */}
          <div className="row">
            <h1 className="text-center py-4">All Bookings Assigned</h1>
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Property Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Booking Date</th>
                    <th>Booking Time</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="6" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) :  (
                    allBookings
                      ?.map((item, i) => {
                        return (
                          <>
                            <tr key={item?._id}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item.propertyTitle}</td>
                              <td>{item.username}</td>
                              <td>{item.email}</td>
                              <td>{item.bookingDate}</td>
                              <td>{item.bookingTime}</td>
                            </tr>
                          </>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignedBooking;
