import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { API_BASE_URL } from "../../config";
import styles from "./deletebooking.module.css";

const DeleteBookingModal = (props) => {
  //  Delete Booking after user comfirmation
  const deleteBooking = async (propertyId, bookingId) => {
    try {
      const res = await axios.delete(
        `${API_BASE_URL}/bookings/property/${propertyId}/cancel-booking/${bookingId}`
      );
      props?.onHide();
      props?.setMyres(res.data);
    } catch (error) {
      console.log(error, 'unable to delete...');
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className={styles.modal_background}>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure to delete this booking?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_background}>
          <div className="booking_container text-dark d-flex justify-content-between">
            <span>Date: {props?.data?.bookingDate}</span>
            <span>Time: {props?.data?.bookingTime}</span>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.modal_background}>
          <Button
            className="btn-dark"
            onClick={() =>
              deleteBooking(props?.data?.propertyID, props?.data?._id)
            }
          >
            Delete
          </Button>
          <Button className="btn-dark" onClick={props?.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteBookingModal;
