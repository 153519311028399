import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL } from "../../../config";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";

const ModalBookingStatus = ({ setShowModal, bookingData, getAllBookings }) => {


  const [bookingStatus, setBookingStatus] = useState(bookingData.bookingStatus);


  const handleSubmit = async () => {
    try {
      const res = await axios.patch(
        `${API_BASE_URL}/bookings/update-booking-status/${bookingData._id}`,
        { bookingStatus }
      );
      if (res.status === 200) {
        NotificationAlert("Status update successfully", "success");
        setShowModal(false);
        getAllBookings();
      }
    } catch (error) {
      NotificationAlert("Failed to update status", "failed");
      console.log(error);
    }
  };
        


  return (
    <>
      <div className="modal_wrapper text-center">
        <div className="modal-form">
          <span className="close-modal" onClick={() => setShowModal(false)}>
            X
          </span>
          <div>
            <label>Booking Status:</label>
            <select onChange={(e) => setBookingStatus(e.target.value)}>
              <option value="Not Available">Not Available</option>
              <option value="Available">Available</option>
              <option value="Completed">Completed</option>
            </select>
          </div>

          <div>
            <button className="modal-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
      <h1 className="text-light">this is Modal</h1>
    </>
  );
};

export default ModalBookingStatus;
