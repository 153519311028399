import axios from "axios";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { API_BASE_URL } from "../config";
import styles from "./passReset.module.css";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { NotificationAlert } from "../componenets/NotificationAlert/NotificationAlert";

export default function PassReset() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const handleResetPassword = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      await axios
        .post(`${API_BASE_URL}/auth/create-otp`, { email })
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            navigate("/opt-verification");
        }
    });
} catch (error) {
        setLoader(false);
        NotificationAlert(error?.response?.data?.message, "failed")

      console.log(error, "unable to reset password");
    }
  };

  return (
    <>
      <div className={`${styles.body}`}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <div className={`${styles.forgot_passHeader}`}>
                  <h1 className="text-center text-white fw-bold">
                    Reset Password
                  </h1>
                </div>

                <div className={`${styles.forgot_passFormContainer} `}>
                  <Form className="text-center ">
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.forgot_passField}`}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </Form.Group>
                    {
                        loader  ?  
                        <Button
                        variant="primary"
                        className={`${styles.forgot_passBtn}`}
                        type="submit"
                        >
                          Loading...
                        </Button>
                        :

                    <Button
                    variant="primary"
                    className={`${styles.forgot_passBtn}`}
                    onClick={handleResetPassword}
                    type="submit"
                    >
                      Reset Password
                    </Button>
                    }
                  </Form>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <h5 className="text-white">OR</h5>
              </div>

              <div className="d-flex mt-4">
                <Link to="#">
                  <img
                    src="./assets/images/authAssets/facebook.png"
                    className="px-1"
                    alt="facebook"
                  />
                </Link>
                <Link to="#">
                  <img
                    src="./assets/images/authAssets/twitter.png"
                    className="px-1"
                    alt="twitter"
                  />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
