import axios from "axios";
import styles from "./offer.module.css";
import { API_BASE_URL } from "../../config";
import React, { useState, useEffect } from "react";
import Header from "../../componenets/Header/Header";
import Footer from "../../componenets/Footer/Footer";

const Offers = () => {
  const [property, setProperty] = useState([]);
  const [loading, setLoading] = useState(true);
  const [propertyLimit, setPropertyLimit] = useState(4);
  const [loadmore, setLoadMore] = useState(false);
  const [search, setSearch] = useState("");

  // Get All Property to show their Bids...
  const getAllProperties = async () => {
    try {
      await axios
        .get(`${API_BASE_URL}/properties/all-properties`)
        .then((res) => {
          setProperty(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // load more properties....
  const loadMoreProperties = () => {
    setLoadMore(true);
    setTimeout(() => {
      setPropertyLimit(propertyLimit + 4);
      setLoadMore(false);
    }, 500);
  };

  useEffect(() => {
    getAllProperties();
  }, []);

  return (
    <>
      <Header />
      <div className="backgound_lines_image">
        <section className="container">
          <h1 className={`${styles.offer_heading} text-center my-5`}>
            <span className={styles.underline_title}>PROPERTY OFFERS</span>
          </h1>
          <div className="text-center">
            <div className={styles.product_filtration_search}>
              <img alt="aa" src="./assets/images/property/search-icon.png" />
              <input
                type="text"
                placeholder="Search Property"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col mx-auto">
              {/* set Loader.. */}
              {loading ? (
                <div className="text-center mt-5">
                  <div className="spinner-border " role="status"></div>
                </div>
              ) : // display All property With bids....
              property && property.length > 0 ? (
                property
                  ?.filter((item) =>
                    item?.title.toLowerCase().includes(search.toLowerCase())
                  )
                  ?.slice(0, propertyLimit)
                  ?.map((item) => (
                    //  biding box...
                    <div
                      key={item?._id}
                      className={`${styles.biding_container} mx-auto mb-5 p-3`}
                    >
                      <h2
                        className={`${styles.main_title} text-lg-start text-center`}
                      >
                        <span className={styles.underline}>{item?.title}</span>
                      </h2>

                      <div className="row">
                        <div className="col-lg-6">
                          <figure
                            className={`${styles.featured_img_container}`}
                          >
                            <img
                              src={`${API_BASE_URL}/${item?.featured_image}`}
                              alt=""
                            />
                          </figure>
                        </div>
                        <div className="col-lg-6">
                          <h3 className={styles.bid_heading}>Biding's</h3>
                          <div className={`${styles.biding_list}`}>
                            {/* show bids of single property */}

                            {item.bids && item?.bids?.length > 0 ? (
                              item?.bids?.map((bid) => (
                                <div
                                  key={bid?._id}
                                  className={`${styles.biding_single_list} me-2 py-2 d-flex justify-content-between`}
                                >
                                  <span>{bid?.username}</span>
                                  <span>$ {bid?.bids}</span>
                                </div>
                              ))
                            ) : (
                              <span>This property have no any bid's</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <h1 className="text-center text-sky">No Offer Available!</h1>
              )}

              {!loading &&
                (loadmore ? (
                  <h2 className="text-light text-center">loading...</h2>
                ) : propertyLimit >= property.length ? (
                  ""
                ) : (
                  <div className="text-center">
                    <button
                      className="loadmore-btn"
                      onClick={loadMoreProperties}
                    >
                      Load More
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Offers;
