import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./allcomments.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { useSelector } from "react-redux";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";


function AllComments() {


  const { dclose } = useContext(myContext);
  const [allComments, setAllComments] = useState([]);
  const userData = useSelector((state) => state.data);
  const [loader, setLoader] = useState(false);

  
  const getAllComments = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/properties/all-properties`)
      .then((res) => {
        setAllComments(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllComments();
  }, []);

  const deleteComments = (bidID, id) => {
    axios
      .delete(
        `${API_BASE_URL}/properties/property/${id}/remove-comment/${bidID}`
      )
      .then((res) => {
        if (res.status === 200) {
          NotificationAlert("Delete Successfully", "success");
          getAllComments();
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationAlert("Failed to delete", "failed");
      });
  };

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          {/* User Listing  */}
          <div className="row">
            <h1 className="text-center py-4">All Comments</h1>
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Property Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Comment</th>
                    <th>Time And Date</th>
                    <th className="text-center">Deletes</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="7" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : userData?.role !== "user" ? (
                    allComments.map((item) => {
                      return (
                        <>
                          {item.comments?.map((comments, i) => {
                            return (
                              <tr key={comments._id}>
                                <td className="text-center">{i + 1}</td>
                                <td>{item.title}</td>
                                <td>{comments.username}</td>
                                <td>{comments.email}</td>
                                <td>{comments.comment}</td>
                                <td>{comments.created_at}</td>
                                <td className="text-center">
                                  <Button
                                    variant="dark"
                                    className={styles.table_btns}
                                    onClick={() =>
                                      deleteComments(comments._id, item._id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })
                  ) : (
                    allComments.map((item) => {
                      return (
                        <>
                          {item.comments
                            ?.filter((item) => item?.email === userData?.email)
                            ?.map((comments, i) => {
                              return (
                                <tr key={comments._id}>
                                  <td className="text-center">{i + 1}</td>
                                  <td>{item.title}</td>
                                  <td>{comments.username}</td>
                                  <td>{comments.email}</td>
                                  <td>{comments.comment}</td>
                                  <td>{comments.created_at}</td>
                                  <td className="text-center">
                                    <Button
                                      variant="dark"
                                      className={styles.table_btns}
                                      onClick={() =>
                                        deleteComments(comments._id, item._id)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllComments;
