import React, { useState } from "react";
import TimePicker from "react-time-picker";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";


const ModalTimeSlotAdd = ({ setShowModal, getTimeSlots }) => {

  const [value, onChange] = useState("10:00");
  const amPmAriaLabel = { am: "am", pm: "pm" };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${API_BASE_URL}/bookingSlots/add-timeslot`, {
        bookingTime: value + ":00",
      });
      if (res.status === 200) {
        NotificationAlert("Time slot added successfully", "success");
        getTimeSlots();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
      NotificationAlert("Failed to add time slot", "failed");
    }
  };

  return (
    <>
      <div className="modal_wrapper text-center">
        <div className="modal-form">
          <span className="close-modal" onClick={() => setShowModal(false)}>
            X
          </span>
          <TimePicker
            className="time-picker"
            onChange={onChange}
            value={value}
            amPmAriaLabel={amPmAriaLabel}
            hourPlaceholder="hh"
          />
          <div>
            <button className="modal-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalTimeSlotAdd;
