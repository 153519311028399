import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./updatemanager.module.css";
import myContext from "../../../context/context";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import axios from "axios";
import ModalManager from "./ModalManager";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";

const UpdateManager = () => {
  
  const { dclose } = useContext(myContext);

  const [showModal, setShowModal] = useState(false);
  const [propertyObj, setProperyObj] = useState({});
  const [loader, setLoader] = useState(false);

  const [updateLawyer, setUpdateLawyer] = useState([]);

  const handleModal = (item) => {
    console.log(item, "single item selected");
    setShowModal(true);
    setProperyObj(item);
  };

  const getUsers = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/all-users`)
      .then((res) => {
        setUpdateLawyer(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const filterManager = updateLawyer.filter(
    (props) => props.role === "manager"
  );

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="row">
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th className="text-center">Updates</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="6" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    filterManager.map((item, i) => {
                      return (
                        <>
                          <tr key={item._id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{item._id}</td>
                            <td>{item.username}</td>
                            <td>{item.email}</td>
                            <td>{item.role}</td>
                            <td className="text-center">
                              <Button
                                variant="dark"
                                className={styles.table_btns}
                                onClick={() => handleModal(item)}
                              >
                                Update
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <ModalManager setShowModal={setShowModal} propertyObj={propertyObj} />
      ) : (
        ""
      )}
    </>
  );
};

export default UpdateManager;
