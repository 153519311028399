import { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../config";
import axios from "axios";

const BookingExpire = () => {
  const [allBookings, setAllBookings] = useState([]);

  const getBooking = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/bookings`);
      setAllBookings(res.data);
    } catch (error) {
      console.log(error, "unable to get bookings");
    }
  };

  useEffect(() => {
    getBooking();
    setInterval(() => {}, 300000);
  }, []);

  const updateBookingStatus = async (booking) => {
    try {
      await axios.patch(
        `${API_BASE_URL}/bookings/property/${booking.propertyID}/booking-status/${booking._id}`,
        {
          bookingStatus: "expired",
        }
      );
    } catch (error) {
      console.log(error, "unable to update booking status");
    }
  };

  

  const now = new Date();
  // const timeString = now.toLocaleTimeString();
  const dateString = now.toLocaleDateString();

  // Get the hours, minutes, and seconds from the current time
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();

  // Format the time in 24-hour format
  const timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  for (const booking of allBookings) {
    if (
      booking.bookingTime <= timeString &&
      booking.bookingDate === dateString
    ) {
      updateBookingStatus(booking);
    }
  }

  return;
};

export default BookingExpire;
