
import styles from "./slider.module.css";
const CardBox = ({link}) => {
  return (
    <>
      <div className={`card m-2 ${styles.card_box}`}>
        <figure className="text-center">
          <img
            src={link}
            className="card-img-top"
            alt="..."
          />
        </figure>
        <div className={`card-body ${styles.card_box_content}`}>
          <h6>Emove lorem</h6>
          <p className="card-text">
            206 East 38th Street, New York 
          </p>
          <span>$ 14,540000</span>
        </div>
      </div>
    </>
  );
};

export default CardBox;