import axios from "axios";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { FaUpload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { API_BASE_URL } from "../../config";
import styles from "./PurchaseModal.module.css";



const PurchaseModal = ({
  propertyTitle,
  setPurchaseModal,
  id,
  setMyres,
}) => {

  const user = useSelector((state) => state);
  const [purchaseFile, setPurchaseFile] = useState(null);
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);

  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (purchaseFile) {
      const formData = new FormData();
      formData.append("userId", user?.data?._id);
      formData.append("propertyID", id);
      formData.append("propertyTitle", propertyTitle);
      formData.append("username", user?.data?.username);
      formData.append("email", user?.data?.email);
      formData.append("file", purchaseFile);

      try {
        const res = await axios.post(`${API_BASE_URL}/purchase`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setMyres(res.data);
        setSuccessAlert(true);
        setValidationAlert(true)
        setAlertMessage("Document Uploaded Successfully")
        alterValidationMessage("Document Uploaded Successfully");
        setPurchaseModal(false);
      } catch (error) {
        console.error("Error:", error);
        setSuccessAlert(false);
        alterValidationMessage("Unable to upload, try later or retry");
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("Upload Your Quotation Plz!");
    }
  };

  const handleFeaturedImageChange = (e) => {
    setPurchaseFile(e.target.files[0]);
  };

  return (
    <>
      <div className={styles.biding_modal_wrapper}>
        {validationAlert ? (
          <div
            className={
              successAlert
                ? "alert alert-success validation-alert d-flex justify-content-between"
                : "alert alert-danger validation-alert d-flex justify-content-between"
            }
            role="alert"
            onClick={() => setValidationAlert(false)}
          >
            <span>{alertMessage}</span>
            <span className="close-alert">X</span>
          </div>
        ) : null}
        <div className={styles.biding_modal_box}>
          <span
            className={styles.close_modal}
            onClick={() => setPurchaseModal(false)}
          >
            X
          </span>
          <h1>Upload Your Quotation</h1>
          <div>
            <Form.Group className={`mb-3`} controlId="formBasicEmail">
              <div>
                <label
                  className={`${styles.addPropImageBtn} my-2`}
                  htmlFor="featured_image"
                >
                  Upload File <FaUpload />
                </label>
                <br />
                <input
                  type="file"
                  className="d-none"
                  id="featured_image"
                  // accept="image/*"
                  accept="image/*, .pdf"
                  onChange={handleFeaturedImageChange}
                />
              </div>
            </Form.Group>
          </div>
          <div className="text-center">
            <Button
              variant="primary"
              className={`${styles.btn}`}
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseModal;
