import React from "react";
import { RouterProvider } from "react-router-dom";
import { protectedRoutes, routes } from "./routes/Route";
import { useSelector } from "react-redux";


function App() {

  // const { userType, setUserType } = useContext(myContext);
  const isUserLogin = useSelector(state => state.isLoggedIn);
  console.log(isUserLogin, 'user user');



  // const user = localStorage.getItem("persist:user");
  // const userData = JSON.parse(user);
  // const userRole = JSON.parse(userData?.data);
  // setUserType(userRole?.role);


  return (
    <>
      <RouterProvider router={isUserLogin ? protectedRoutes : routes} />
    </>
  );
}


export default App;