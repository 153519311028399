import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./purchaseListing.module.css";
import myContext from "../../../context/context";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { Link } from "react-router-dom";
import PurchasePropModal from "./PurchaseModal";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { useSelector } from "react-redux";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";

const PurchaseListing = () => {


  const { dclose } = useContext(myContext);
  const userData = useSelector((state) => state.data);
  const [loader, setLoader] = useState(false);
  const [purchased, setPurchased] = useState([]);
  const [purchasedModal, setPurchasedModal] = useState(false);
  const [status, setStatus] = useState({
    status: "sold",
  });

  const getPurchaseLists = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/purchase/get-purchased-properties`)
      .then((res) => {
        console.log(res.data, 'purchase.....');
        setPurchased(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPurchaseLists();
  }, []);

  const propertyStatus = (item) => {
    setPurchasedModal(true);
    setStatus(item);
  };

  const deleteListing = (id) => {
    axios
      .delete(`${API_BASE_URL}/purchase/delete/${id}`)
      .then((res) => {
        if (res.status === 200) {
          NotificationAlert("Delete Successfully", "success");
          getPurchaseLists();
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationAlert("Failed to delete", "failed");
      });
  };

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="row">
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Property Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th className="text-center">Tendor Files</th>
                    <th className="text-center">Delete Quotations</th>
                    <th className="text-center"> Accept Quotations</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="7" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : userData?.role !== "user" ? (
                    purchased.map((item, i) => {
                      return (
                        <>
                          <tr key={item._id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{item.propertyTitle}</td>
                            <td>{item.username}</td>
                            <td>{item.email}</td>
                            <td className="py-2 text-center">
                              <button className={`${styles.table_btns} btn`}>
                                <Link
                                  className="text-dark"
                                  to={`${API_BASE_URL}/uploads/${item.file}`}
                                >
                                  Download
                                </Link>
                              </button>
                            </td>
                            <td className="text-center">
                              <Button
                                variant="dark"
                                className={styles.table_btns}
                                onClick={() => deleteListing(item._id)}
                              >
                                Delete Quotations
                              </Button>
                            </td>
                            <td className="text-center">
                              <Button
                                variant="dark"
                                className={styles.table_btns}
                                onClick={() => propertyStatus(item)}
                              >
                                Accept Quotations
                              </Button>
                              {purchasedModal ? (
                                <PurchasePropModal
                                  purchasedModal={purchasedModal}
                                  setPurchasedModal={setPurchasedModal}
                                  status={status}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    purchased
                      ?.filter((item) => item?.email === userData?.email)
                      ?.map((item, i) => {
                        return (
                          <>
                            <tr key={item._id}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item.propertyTitle}</td>
                              <td>{item.username}</td>
                              <td>{item.email}</td>
                              <td className="py-2 text-center">
                                <button className={`${styles.table_btns} btn`}>
                                  <Link
                                    className="text-dark"
                                    to={`${API_BASE_URL}/uploads/${item.file}`}
                                  >
                                    Download
                                  </Link>
                                </button>
                              </td>
                              <td className="text-center">
                                <Button
                                  variant="dark"
                                  className={styles.table_btns}
                                  onClick={() => deleteListing(item._id)}
                                >
                                  Delete Quotations
                                </Button>
                              </td>
                              <td className="text-center">
                                <Button
                                  variant="dark"
                                  className={styles.table_btns}
                                  onClick={() => propertyStatus(item)}
                                >
                                  Accept Quotations
                                </Button>
                                {purchasedModal ? (
                                  <PurchasePropModal
                                    purchasedModal={purchasedModal}
                                    setPurchasedModal={setPurchasedModal}
                                    status={status}
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseListing;
