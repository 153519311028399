import React, { useState } from "react";
import styles from "./login.module.css";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { login } from "../Redux/reducers/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing icons

export default function Login() {
  const [validationAlert, setValidationAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const { email, password } = userLogin;

  const handleInputs = (e) => {
    setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);

    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  const formValidation = () => {
    if (userLogin.email && userLogin.password) {
      return true;
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All fields are required");
    }
    return false;
  };

  const onSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    if (true) {
      try {
        dispatch(login(userLogin)).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            setLoader(false);
            navigate("/");
          } else if (response.meta.requestStatus === "rejected") {
            setSuccessAlert(false);
            setLoader(false);
            alterValidationMessage("Invalid Email or Password");
          }
        });
      } catch (error) {
        setSuccessAlert(false);
        setLoader(false);
        alterValidationMessage("Unable to login. Please try again later.");
      }
    }
  };

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <div className={`${styles.body}`}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <div className={`${styles.loginHeader}`}>
                  <h1 className="text-center text-white fw-bold">LOGIN</h1>
                </div>

                <div className={`${styles.loginFormContainer}`}>
                  <Form className="text-center" onSubmit={onSubmit}>
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.loginField}`}
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group
                      className={`mb-3 position-relative`}
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        className={`${styles.loginField}`}
                        type={passwordVisible ? "text" : "password"} // Toggle input type
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={handleInputs}
                      />
                      <span
                        className={styles.passwordToggleIcon}
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </Form.Group>

                    <div className="text-start d-flex justify-content-between">
                      <h6 className="text-white">
                        <Link
                          className="text-decoration-none"
                          style={{ color: "#97d2ec" }}
                          to="/reset-password"
                        >
                          Forgot Password?
                        </Link>
                      </h6>
                    </div>
                    {loader ? (
                      <Button
                        variant="primary"
                        className={`${styles.loginBtn}`}
                        type="submit"
                        disabled
                      >
                        Login
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        className={`${styles.loginBtn}`}
                        type="submit"
                      >
                        Login
                      </Button>
                    )}
                  </Form>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <h5 className="text-white">OR</h5>
              </div>

              <div className="d-flex mt-4">
                <Link to="#">
                  <img
                    src="./assets/images/authAssets/facebook.png"
                    className="px-1"
                    alt="facebook"
                  />
                </Link>
                <Link to="#">
                  <img
                    src="./assets/images/authAssets/twitter.png"
                    className="px-1"
                    alt="twitter"
                  />
                </Link>
              </div>

              <div>
                <h6 className="text-white mt-5">
                  Don't Have An Account?{" "}
                  <Link
                    className={`text-decoration-none ${styles.button_color}`}
                    to="/signup"
                  >
                    Signup
                  </Link>
                </h6>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
