import Slider from "react-slick";
import styles from "./galleryslider.module.css";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
import GalleryView from "../GalleryView/GalleryView";
import { useState } from "react";

const imgLinks = [
  "./assets/images/home/garden1.png",
  "./assets/images/home/garden2.png",
  "./assets/images/home/garden3.png",
];

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div>
      <MdOutlineArrowForwardIos
        className={`${className} ${styles.arrowNext} `}
        onClick={onClick}
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={``}>
      <MdOutlineArrowBackIosNew
        className={`${className} ${styles.arrowPrev} `}
        onClick={onClick}
      />
    </div>
  );
};

const GallerySlider = () => {
  const [showGallery, setShowGallery] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.7,
    slidesToScroll: 1,
    initialSlide: 0,
    autoPlay: true,
    autoPlaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <GalleryView
        setShowGallery={setShowGallery}
        showGallery={showGallery}
        imgLinks={imgLinks}
      />
      <Slider {...settings} className={`${styles.slider_wrapper}  mt-5 pt-5`}>
        {/* xxxxxxxxxxxxxxx */}
        <div className={`card ${styles.card_box}`}>
          <figure className="text-center">
            <img
              src="./assets/images/home/garden1.png"
              className="card-img-top"
              alt="..."
            />
          </figure>
          <span
            className={`${styles.view}`}
            onClick={() => setShowGallery(true)}
          >
            VIEW
          </span>
        </div>
        {/* xxxxxxxxxxxxxxx */}
        <div className={`card ${styles.card_box}`}>
          <figure className="text-center">
            <img
              src="./assets/images/home/garden2.png"
              className="card-img-top"
              alt="..."
            />
          </figure>
          <span
            className={`${styles.view}`}
            onClick={() => setShowGallery(true)}
          >
            VIEW
          </span>
        </div>
        {/* xxxxxxxxxxxxxxx */}
        <div className={`card ${styles.card_box}`}>
          <figure className="text-center">
            <img
              src="./assets/images/home/garden3.png"
              className="card-img-top"
              alt="..."
            />
          </figure>
          <span
            className={`${styles.view}`}
            onClick={() => setShowGallery(true)}
          >
            VIEW
          </span>
        </div>
      </Slider>
    </>
  );
};

export default GallerySlider;
