import Slider from "react-slick";
import CardBox from "./CardBox";
import styles from "./slider.module.css";

import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
const imgLinks = [
  "./assets/images/home/slider1.png",
  "./assets/images/home/slider2.png",
  "./assets/images/home/slider3.png",
  "./assets/images/home/slider2.png",
];

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div>
      <MdOutlineArrowForwardIos
        className={`${className} ${styles.arrowStyle} `}
        style={{ color: "black", top: "0", right: "0%" }}
        onClick={onClick}
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={``}>
      <MdOutlineArrowBackIosNew
        className={`${className} ${styles.arrowStyle} `}
        style={{ fontSize: "100px", color: "black", top: "0", left: "85%" }}
        onClick={onClick}
      />
    </div>
  );
};

const CardSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoPlay: true,
    autoPlaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} className="py-5">
        {imgLinks?.map((link, i) => (
          <CardBox key={i} link={link} />
        ))}
      </Slider>
    </>
  );
};

export default CardSlider;
