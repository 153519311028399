import React, { useContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { useEffect } from "react";
import styles from "../PersonalRepList/PersonalRepList.module.css";
// import styles from "./AttorneyList.module.css";

const AssignmentModalAgent = ({ userData, setModalShow }) => {


  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [assignments, setAssignments] = useState([]);


  const viewAssignments = async () => {
    setLoader(true);
    console.log(userData, "sssss");
    try {
      const res = await axios.get(
        `${API_BASE_URL}/properties/assigned-properties-to-agent/${userData}`
      );
      setAssignments(res.data);
      console.log(res.data, "perticular data assigned........");
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };


  const deleteAssignment = async (assignedProperty) => {
    try {
      const res = await axios.patch(
        `${API_BASE_URL}/properties/${assignedProperty._id}/remove-agent-assignments`,
        {
          PRassignedBy: assignedProperty.PRassignedBy[0],
          assignedPersonalRep: assignedProperty.assignedPersonalRep[0],
        }
      );
      viewAssignments();
    } catch (error) {
      console.log(error, "Unable to unassigned property...");
    }
  };


  useEffect(() => {
    viewAssignments();
  }, []);

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <div className="modal_wrapper text-center">
        <div className="modal-form">
          <span className="close-modal" onClick={() => setModalShow(false)}>
            X
          </span>

          <h3>Assigned Properties</h3>
          <div className={`${styles.assigned_property_list_container} py-2`}>
            <span className="ps-2">NO.</span>
            <span>PROPERTY</span>
            <span className="pe-3">DELETE</span>
          </div>
          {!loader ? (
            assignments && assignments.length > 0 ? (
              assignments.map((item, i) => (
                <>
                  <div
                    key={item._id}
                    className={`${styles.assigned_property_list} py-2`}
                  >
                    <span className="pt-2 ps-3">{i + 1}</span>
                    <span className="pt-2">{item.title}</span>
                    <button
                      className="me-3"
                      onClick={() => deleteAssignment(item)}
                    >
                      Delete
                    </button>
                  </div>
                </>
              ))
            ) : (
              <h1 className="text-sky">No Assignments Yet</h1>
            )
          ) : (
            <h3 className="text-sky">Loading...</h3>
          )}
        </div>
      </div>
    </>
  );
};

export default AssignmentModalAgent;
