import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import styles from "./bookingModal.module.css";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";

const BookingModal = ({ singleProperty, setBookingModal, id, setMyres }) => {
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);

  const user = useSelector((state) => state);
  const navigate = useNavigate();

  // For Calendar
  const [startDate, setStartDate] = useState(new Date());

  // Booking date and time is reserved here
  const [bookingTime, setBookingTime] = useState("");
  const [allBookings, setAllBookings] = useState([]);
  const [bookingTimes, setBookingTimes] = useState([]);

  // Fetching User's data from redux
  const userData = useSelector((state) => state.data);

  // Further property details
  const [furtherDetails, setFurtherDetails] = useState({
    username: userData.username,
    userId: userData._id,
    email: userData.email,
    propertyID: id,
    propertyTitle: singleProperty,
    bookingDate: startDate,
    bookingTime: bookingTime,
  });

  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // handle time slote...
  const timeSlot = (time) => {
    setBookingTime(time);
  };

  // get all previous booking for validate new booking...
  const getBooking = () => {
    axios
      .get(`${API_BASE_URL}/bookings`)
      .then((res) => {
        setAllBookings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get booking slots times for validate new booking.....
  const getBookingTimes = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/bookingSlots/all-timeslots`);
      setBookingTimes(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBooking();
    getBookingTimes();
    setFurtherDetails({
      ...furtherDetails,
      bookingDate: startDate.toLocaleDateString(),
      bookingTime: bookingTime,
    });
  }, [bookingTime]);

  // booking validation function...
  const checkEquality = () => {
    const currentPropertyBooking = allBookings.filter(
      (item) => item.propertyTitle === singleProperty
    );

    for (let i = 0; i < currentPropertyBooking.length; i++) {
      if (
        currentPropertyBooking[i].bookingDate === furtherDetails.bookingDate &&
        currentPropertyBooking[i].bookingTime === furtherDetails.bookingTime
      ) {
        return true;
      }
    }
    return false;
  };

  // post new booking after validate user authentication and booking validation..
  const handleSubmit = async () => {
    if (user.isLoggedIn) {
      if (furtherDetails.bookingDate && furtherDetails.bookingTime) {
        if (checkEquality()) {
          setSuccessAlert(false);
          alterValidationMessage(
            "Booking slot not available, check another slot!"
          );
        } else {
          try {
            const res = await axios.post(
              `${API_BASE_URL}/bookings/property/${id}`,
              furtherDetails
            );
            setMyres(res.data);
            setBookingModal(false);
          } catch (err) {
            console.log(err, "not posted biding price");
            setSuccessAlert(false);
            alterValidationMessage(
              "Unable to post biding price, try later or retry!"
            );
          }
        }
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Plz Select Booking Date & Time");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className={styles.biding_modal_wrapper}>
        {validationAlert ? (
          <div
            className={
              successAlert
                ? "alert alert-success validation-alert d-flex justify-content-between"
                : "alert alert-danger validation-alert d-flex justify-content-between"
            }
            role="alert"
            onClick={() => setValidationAlert(false)}
          >
            <span>{alertMessage}</span>
            <span className="close-alert">X</span>
          </div>
        ) : null}
        <div className={styles.biding_modal_box}>
          <span
            className={styles.close_modal}
            onClick={() => setBookingModal(false)}
          >
            X
          </span>

          <Container fluid>
            <Row className="p-3">
              <Col lg="6" className="">
                {/* Calendar will go here  */}
                <h1 className={`${styles.modal_heading} mb-3`}>
                  <span>Select Date</span>
                </h1>
                <div className="mb-3 d-flex justify-content-center align-items-center text-center">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <h1 className={`${styles.modal_heading}`}>
                  <span>Select Time</span>
                </h1>
                <div className="d-lg-inline-block d-flex flex-column justify-content-center align-items-center">
                  {bookingTimes?.map((slots) => {
                    return (
                      <div
                        className={`${styles.time_slots_box} d-flex  justify-content-center`}
                        key={slots?._id}
                      >
                        <input
                          type="checkbox"
                          checked={bookingTime === slots?.bookingTime}
                          onChange={() => timeSlot(slots?.bookingTime)}
                          className={styles.time_checkbox}
                        />
                        <label className={styles.time_lables}>
                          {slots?.bookingTime}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Col>
              <div className="d-block text-center">
                <Button
                  variant="primary"
                  className={`my-3 ${styles.booking_modal_btn}`}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default BookingModal;
