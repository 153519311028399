import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./unblockuser.module.css";
import myContext from "../../../context/context";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import axios from "axios";
import ModalUnblockUser from "./ModalUnblockUser";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";


const UnblockUser = () => {


  const { dclose } = useContext(myContext);
  const [showModal, setShowModal] = useState(false);
  const [blockedUser, setBlockedUser] = useState({});
  const [updateLawyer, setUpdateLawyer] = useState([]);
  const [loader, setLoader] = useState(false);

  
  const getUsers = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/all-users`)
      .then((res) => {
        setUpdateLawyer(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    getUsers();
  }, [showModal]);

  const handleModal = (item) => {
    setShowModal(true);
    setBlockedUser(item);
  };

  const filterBlock = updateLawyer.filter((props) => props.role === "block");

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="row">
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th className="text-center">Unblock</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="6" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : filterBlock.length > 0 ? (
                    filterBlock.map((item, i) => {
                      return (
                        <>
                          <tr key={item._id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{item._id}</td>
                            <td>{item.username}</td>
                            <td>{item.email}</td>
                            <td>{item.role}</td>
                            <td className="text-center">
                              <Button
                                variant="dark"
                                className={styles.table_btns}
                                onClick={() => handleModal(item)}
                              >
                                Update
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <div className="text-center py-4">
                      <h1> There are no blocked users </h1>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <ModalUnblockUser
          setShowModal={setShowModal}
          blockedUser={blockedUser}
        />
      ) : (
        ""
      )}
    </>
  );
};


export default UnblockUser;
