import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";



const ModalX = ({ setShowModal, propertyObj }) => {

  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [title, setTitle] = useState(propertyObj.title);
  const [address, setAddress] = useState(propertyObj.address);
  const [price, setPrice] = useState(propertyObj.price);
  const [description, setDescription] = useState(propertyObj.description);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);
  const [status, setStatus] = useState("")


  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  
  // validation
  const formValidation = () => {
    if (title && address && price && description && featuredImage && galleryImages) {
      if (galleryImages.length > 10) {
        setSuccessAlert(false);
        alterValidationMessage("You Upload Maximum 10 Images");
      }else{
          return true;
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formValidation()) {

    const formData = new FormData();
    formData.append('title', title);
    formData.append('address', address);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('status', status);
    formData.append('featured_image', featuredImage);
    for (const image of galleryImages) {
      formData.append('gallery_images', image);
    }

    try {

      await axios.patch(`${API_BASE_URL}/properties/update-property/${propertyObj._id}`, formData, {
        headers: {
          "Content-Type": 'multipart/form-data'
        }
      })
      setShowModal(false);
    } catch (error) {
      console.log('Error:', error);
      setSuccessAlert(false);
      alterValidationMessage("Unable to Add New Property");
    }
  }
  }

  const handleFeaturedImageChange = (e) => {
    setFeaturedImage(e.target.files[0]);
  }

  const handleGalleryImageChange = (e) => {
    setGalleryImages(e.target.files);
  }

  return (
    <>
 {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <div className="modal_wrapper text-center">
      <div className="modal-form">
        <span className="close-modal" onClick={() => setShowModal(false)}>X</span>


        <form onSubmit={handleSubmit}>
          <div className="form-input-container">
            <label>Title:</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className="form-input-container">
            <label>Address:</label>
            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
          </div>
          <div className="form-input-container">
            <label>Price:</label>
            <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
          </div>
          <div className="form-input-container">
            <label>Description:</label>
            <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
          </div>

          <div className="form-input-container">
            <label>Status:</label>

            <select onChange={(e) => setStatus(e.target.value)}>
              <option value="unsold">unsold</option>
              <option value="sold">sold</option>
            </select>
          </div>

          <div className="form-input-container">
            <label>Featured Image:</label>
            <input type="file" accept="image/*" onChange={handleFeaturedImageChange} />
          </div>
          <div className="form-input-container">
            <label>Gallery Images:</label>
            <input type="file" accept="image/*" multiple onChange={handleGalleryImageChange} />
          </div>
          <div>
            <button className="modal-btn" type="submit">Submit</button>
          </div>
        </form>

      </div>
      </div>
    </>
  );
};

export default ModalX;
