import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./timeslots.module.css";
import myContext from "../../../context/context";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import ModalTimeSlotAdd from "./ModalTimeSlotAdd";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";

const TimeSlotsAddAndGet = () => {


  const { dclose } = useContext(myContext);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);


  const handleModal = () => {
    setShowModal(true);
  };


  const getTimeSlots = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/bookingSlots/all-timeslots`)
      .then((res) => {
        const uniqueTimeSlots = res.data.filter(
          (timeSlot, index, self) =>
            index ===
            self.findIndex((t) => t.bookingTime === timeSlot.bookingTime)
        );
        setTimeSlots(uniqueTimeSlots);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  const deleteSlot = (id) => {
    axios
      .delete(`${API_BASE_URL}/bookingSlots/remove-timeslot/${id}`)
      .then((res) => {
        if (res.status === 200) {
          NotificationAlert("Delete Successfully", "success");
          getTimeSlots();
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationAlert("Failed to delete", "failed");
      });
  };


  useEffect(() => {
    getTimeSlots();
  }, [setShowModal]);


  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="row mt-5">
            <h1 className="text-center my-2">TIME SLOTS</h1>

            <Button
              variant="dark"
              className={styles.add_time_btns}
              onClick={() => handleModal()}
            >
              ADD NEW TIME SLOTS
            </Button>

            {showModal ? <ModalTimeSlotAdd setShowModal={setShowModal} getTimeSlots={getTimeSlots} /> : ""}
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th>#</th>
                    <th>Booking Slots</th>
                    <th>Delete Slot</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="5" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : timeSlots.length > 0 ? (
                    timeSlots.map((item, i) => {
                      return (
                        <>
                          <tr key={item._id}>
                            <td>{i + 1}</td>
                            <td>{item.bookingTime}</td>
                            <td>
                              <Button
                                variant="dark"
                                className={styles.table_btns}
                                onClick={() => deleteSlot(item._id)}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <div className="text-center py-4">
                      <h1> There are no blocked users </h1>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeSlotsAddAndGet;
