import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { TiBookmark } from "react-icons/ti";
import myContext from "../../../context/context";
import { SlPeople, SlHome } from "react-icons/sl";
import { BsCurrencyDollar } from "react-icons/bs";
import { BiPurchaseTag, BiCommentDetail } from "react-icons/bi";
import { GrUserAdmin, GrUserManager, GrUser } from "react-icons/gr";
import styles from "../../dashboardpages/Dashboardmain/dashboard.module.css";

const DSidebar = () => {
  
  const { dclose, dcloseSidebar } = useContext(myContext);
  const userData = useSelector((state) => state);

  return (
    <div className={dclose ? `${styles.sidebar_hide}` : `${styles.sidebar}`}>
      <div className={`${styles.close}`}>
        <span onClick={dcloseSidebar}>X</span>
      </div>
      <div className={`${styles.sidebar_heading}`}>
        <NavLink to="/">
          <img
            src="../../assets/images/home/realstate.png"
            alt=""
            className={styles.d_logo}
          />
        </NavLink>
      </div>
      <div className={`${styles.sidebar_menu} mt-5`}>
        <div className={`${styles.menu}`}>
          {/* manu list */}
          {/* Manage Roles and Poeples */}


          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* Manage Admins */}
          {userData.isLoggedIn && userData?.data?.role === "super admin" ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <GrUserAdmin className={styles.d_icon} /> Manage Admins
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-admin"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Add Admin
                </NavLink>
                <NavLink
                  to="/dashboard/admins-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Admins
                </NavLink>
              </ul>
            </div>
          ) : null}


          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* Manage Personal Rep */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "manager" ||
            userData?.data?.role === "admin" ||
            userData?.data?.role === "super admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <GrUserAdmin className={styles.d_icon} /> Manage Personal Rep
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-personal-rep"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Add Personal Rep
                </NavLink>
                <NavLink
                  to="/dashboard/personal-rep-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Personal Reps
                </NavLink>
                <NavLink
                  to="/dashboard/assigned-personalreps-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Assigned Personal Reps
                </NavLink>
              </ul>
            </div>
          ) : null}





          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* Manage Agents */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "manager" ||
            userData?.data?.role === "super admin" ||
            userData?.data?.role === "admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <GrUserAdmin className={styles.d_icon} /> Manage Agents
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-agents"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Add Agents
                </NavLink>
                <NavLink
                  to="/dashboard/agent-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Agents
                </NavLink>
                <NavLink
                  to="/dashboard/assigned-agent-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Assigned Agents
                </NavLink>
              </ul>
            </div>
          ) : null}




          
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* Manage Team Associates */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "manager" ||
            userData?.data?.role === "admin" ||
            userData?.data?.role === "super admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <GrUserAdmin className={styles.d_icon} /> Manage Team Associates
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-team-associates"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Add Team Associates
                </NavLink>
                <NavLink
                  to="/dashboard/team-associates-listing"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Team Associate Listings
                </NavLink>
                <NavLink
                  to="/dashboard/assigned-teamassociate-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Assigned Team Associate
                </NavLink>
              </ul>
            </div>
          ) : null}




          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* Manage Attorneys */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "manager" ||
            userData?.data?.role === "admin" ||
            userData?.data?.role === "super admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <GrUserAdmin className={styles.d_icon} /> Manage Attorneys
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-attorneys"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Add Attorneys
                </NavLink>
                <NavLink
                  to="/dashboard/attorney-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Attorney Listings
                </NavLink>
                <NavLink
                  to="/dashboard/assigned-attorney-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Assigned Attorney
                </NavLink>
              </ul>
            </div>
          ) : null}





          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}



          {/* manage Managers */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "admin" ||
            userData?.data?.role === "super admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <GrUserManager className={styles.d_icon} /> Manage Managers
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-manager"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Add Manager
                </NavLink>
                <NavLink
                  to="/dashboard/update-manager"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Update Manager
                </NavLink>
                <NavLink
                  to="/dashboard/managers-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Managers
                </NavLink>
              </ul>
            </div>
          ) : null}

          {/* Manage Lawyers */}

          {/* {userData.isLoggedIn &&
            (userData?.data?.role === "manager" ||
            userData?.data?.role === "admin" ||
              userData?.data?.role === "super admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <TfiUser className={styles.d_icon} /> Manage Lawyers
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-lawyer"
                   className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Add Lawyer
                </NavLink>
                <NavLink
                  to="/dashboard/update-lawyer"
                   className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Update Lawyer
                </NavLink>
                <NavLink
                  to="/dashboard/lawyers-list"
                   className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Lawyers
                </NavLink>
              </ul>
            </div>
          ) : null} */}






          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}



          {/* Manage User */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "manager" ||
            userData?.data?.role === "admin" ||
            userData?.data?.role === "super admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <GrUser className={styles.d_icon} /> Manage Users
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-user"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Add User
                </NavLink>
                <NavLink
                  to="/dashboard/update-user"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Update User
                </NavLink>

                <NavLink
                  to="/dashboard/users-list"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Users
                </NavLink>
              </ul>
            </div>
          ) : null}





          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* Manage Roles */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "manager" ||
            userData?.data?.role === "admin" ||
            userData?.data?.role === "super admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <SlPeople className={styles.d_icon} /> Manage Roles
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/block-user"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Block Roles
                </NavLink>
                <NavLink
                  to="/dashboard/unblock-user"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Unblock Roles
                </NavLink>
                <NavLink
                  to="/dashboard/all-listed-users"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  History of Users
                </NavLink>
              </ul>
            </div>
          ) : null}

          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxx Manage Property ......xxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}



          
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* Manage Properties for concern */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "manager" ||
            userData?.data?.role === "admin" ||
            userData?.data?.role === "super admin") ? (
              <div className={`${styles.dashboard_options}`}>
              <h4>
                <SlHome className={styles.d_icon} /> Manage Properties
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-property"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                  >
                  Add property
                </NavLink>
                <NavLink
                  to="/dashboard/all-listed-properties"
                  className={({ isActive }) =>
                    isActive
                  ? `${styles.dashboard_navlink_active}`
                  : `${styles.dashboard_navlink}`
                  }
                >
                  All Listed Property
                </NavLink>
                <NavLink
                  to="/dashboard/update-properties"
                  className={({ isActive }) =>
                    isActive
                  ? `${styles.dashboard_navlink_active}`
                  : `${styles.dashboard_navlink}`
                }
                >
                  Update Properties
                </NavLink>
                <NavLink
                  to="/dashboard/delete-properties"
                  className={({ isActive }) =>
                    isActive
                  ? `${styles.dashboard_navlink_active}`
                  : `${styles.dashboard_navlink}`
                  }
                >
                  Delete Properties
                </NavLink>
              </ul>
            </div>
          ) : null}

          {/* Manage Properties for agent */}
          {userData.isLoggedIn &&
          ( userData?.data?.role === "agent" ) ? (
              <div className={`${styles.dashboard_options}`}>
              <h4>
                <SlHome className={styles.d_icon} /> Manage Properties
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/add-property"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                  >
                  Add property
                </NavLink>
                <NavLink
                  to="/dashboard/all-listed-properties"
                  className={({ isActive }) =>
                    isActive
                  ? `${styles.dashboard_navlink_active}`
                  : `${styles.dashboard_navlink}`
                  }
                >
                  All Listed Property
                </NavLink>
                <NavLink
                  to="/dashboard/assigned-agent-property"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Assigned Property
                </NavLink>
              </ul>
            </div>
          ) : null}

          
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* Manager property for Team Associate */}
          {userData.isLoggedIn && userData?.data?.role === "team associates" ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <SlHome className={styles.d_icon} /> Manage Properties
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/all-listed-properties"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Listed Property
                </NavLink>
                <NavLink
                  to="/dashboard/update-properties"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Update Properties
                </NavLink>
              </ul>
            </div>
          ) : null}




          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* Manage property for only Personal Reps */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "personal rep") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <SlHome className={styles.d_icon} /> Manage Properties
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/all-listed-properties"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Listed Property
                </NavLink>
                <NavLink
                  to="/dashboard/assigned-personalrep-property"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Assigned Property
                </NavLink>
              </ul>
            </div>
          ) : null}



          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
         



          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* Manage property for only Attorney */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "attorney") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <SlHome className={styles.d_icon} /> Manage Properties
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/all-listed-properties"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Listed Property
                </NavLink>
                <NavLink
                  to="/dashboard/assigned-attorney-property"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Assigned Property
                </NavLink>
              </ul>
            </div>
          ) : null}

          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxx   Manage Booking..........xxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}



          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* Manage Booking for Top Concern */}
          {(userData.isLoggedIn && userData?.data?.role === "super admin") ||
          userData?.data?.role === "admin" ||
          userData?.data?.role === "manager" ||
          userData?.data?.role === "agent" ||
          userData?.data?.role === "personal rep" ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <TiBookmark className={styles.d_icon} /> Manage Bookings
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/all-bookings"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Bookings
                </NavLink>

                <>
                  <NavLink
                    to="/dashboard/all-timeSlots"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.dashboard_navlink_active}`
                        : `${styles.dashboard_navlink}`
                    }
                  >
                    Add Time Slots
                  </NavLink>
                  <NavLink
                    to="/dashboard/update-bookings"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.dashboard_navlink_active}`
                        : `${styles.dashboard_navlink}`
                    }
                  >
                    Bookings Status
                  </NavLink>
                  <NavLink
                    to="/dashboard/completed-bookings"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.dashboard_navlink_active}`
                        : `${styles.dashboard_navlink}`
                    }
                  >
                    Completed Bookings
                  </NavLink>
                </>
              </ul>
            </div>
          ) : (
            ""
          )}



          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* Manage Booking for team associate */}
          {userData.isLoggedIn && userData?.data?.role === "team associates" ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <TiBookmark className={styles.d_icon} /> Manage Bookings
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/all-bookings-assigned"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Bookings
                </NavLink>

                <NavLink
                  to="/dashboard/update-bookings-assigned"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Bookings Status
                </NavLink>
                <NavLink
                  to="/dashboard/completed-bookings"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Completed Bookings
                </NavLink>
              </ul>
            </div>
          ) : (
            ""
          )}





          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}


          {/* Manage Booking for register user */}
          {userData.isLoggedIn && userData?.data?.role === "user" ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <TiBookmark className={styles.d_icon} /> Manage Bookings
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/all-bookings"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Bookings
                </NavLink>
              </ul>
            </div>
          ) : (
            ""
          )}

          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

          {/* Manage Bids */}
          <div className={`${styles.dashboard_options}`}>
            <h4>
              <BsCurrencyDollar className={styles.d_icon} /> Manage Bids
            </h4>
            <ul className="list-unstyled  d-flex flex-column">
              {userData.isLoggedIn && userData?.data?.role !== "user" ? (
                <NavLink
                  to="/dashboard/all-bids"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  All Bids
                </NavLink>
              ) : null}
              <NavLink
                to="/dashboard/user-bids"
                className={({ isActive }) =>
                  isActive
                    ? `${styles.dashboard_navlink_active}`
                    : `${styles.dashboard_navlink}`
                }
              >
                Bids
              </NavLink>
            </ul>
          </div>




          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* Manage Purchased listing */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "manager" ||
            userData?.data?.role === "super admin" ||
            userData?.data?.role === "user" ||
            userData?.data?.role === "admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <BiPurchaseTag className={styles.d_icon} /> Purchased Listings
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/purchased-properties"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Purchased Listings
                </NavLink>
              </ul>
            </div>
          ) : null}



          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* Manage purchase listing for attorney */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "attorney") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <BiPurchaseTag className={styles.d_icon} /> Purchased Listings
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/purchased-properties-attorney"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Purchased Listings
                </NavLink>
              </ul>
            </div>
          ) : null}

        
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          {/*  Manage Comments.. */}
          {userData.isLoggedIn &&
          (userData?.data?.role === "user" ||
            userData?.data?.role === "manager" ||
            userData?.data?.role === "super admin" ||
            userData?.data?.role === "admin") ? (
            <div className={`${styles.dashboard_options}`}>
              <h4>
                <BiCommentDetail className={styles.d_icon} /> Utills
              </h4>
              <ul className="list-unstyled  d-flex flex-column">
                <NavLink
                  to="/dashboard/all-comments"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.dashboard_navlink_active}`
                      : `${styles.dashboard_navlink}`
                  }
                >
                  Comments
                </NavLink>
              </ul>
            </div>
          ) : null}

          {/* manu list */}
        </div>
      </div>
    </div>
  );
};

export default DSidebar;
