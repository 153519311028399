import React, { useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./addadmin.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";

function AddAdmin() {

  
  const { dclose } = useContext(myContext);
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);

  const [adminAdd, setAdminAdd] = useState({
    username: "",
    email: "",
    password: "",
    cpassword: "",
    role: "admin",
  });

  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);

    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  
// validation 
  const formValidation = () => {
    if (
      adminAdd.username &&
      adminAdd.email &&
      adminAdd.password &&
      adminAdd.cpassword
    ) {
      if (adminAdd.password === adminAdd.cpassword) {
        const passwordRegex =
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        var emailRegex =/^\w+[\w.-]*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

        if (emailRegex.test(adminAdd.email)) {
          if (passwordRegex.test(adminAdd.password)) {
            return true;
          } else {
            setSuccessAlert(false);
            alterValidationMessage(
              "Password contain 6 to 16 valid characters and at least one number and on special character!"
            );
          }
        } else {
          setSuccessAlert(false);
          alterValidationMessage(
            "Email is not valid"
          );
        }
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Passwords Not Match!");
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };

  
  const submitAdmin = async () => {
    if (formValidation()) {
      try {
        await axios
          .post(`${API_BASE_URL}/auth/register`, {
            username: adminAdd.username,
            email: adminAdd.email,
            password: adminAdd.password,
            role: "admin",
          })
          .then((res) => {
            console.log(res.status, );
            if (res.status === 200) {
            setSuccessAlert(true);
            alterValidationMessage("Successfully Added!");
            setAdminAdd({
              username: "",
              email: "",
              password: "",
              cpassword: "",
              role: "admin",
            });
          }
          });
      } catch (error) {
        console.log(error);
        setSuccessAlert(false);
        alterValidationMessage("Unable to Add User. retry or try later");
      }
    }
  };

  const handleInputs = (e) => {
    setAdminAdd({ ...adminAdd, [e.target.name]: e.target.value });
  };

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}

      <DSidebar />
      <TopNav />

      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes/>
          </div>

          <div className="row mt-3">
          <h1 className="text-center mt-5">Add Admin</h1>
            
            {/* xxxxxxx Add New Admin xxxxxxxxxxxx */}
            <div className={`${styles.add_admin_form}`}>
              <div className="mb-3">
                <input
                  type="text"
                  name="username"
                  className={`${styles.add_admin_field} form-control`}
                  placeholder="Enter Admin Name"
                  value={adminAdd.username}
                  onChange={handleInputs}
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  name="email"
                  className={`${styles.add_admin_field} form-control`}
                  placeholder="Enter Admin Email"
                  value={adminAdd.email}
                  onChange={handleInputs}
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  name="password"
                  className={`${styles.add_admin_field} form-control`}
                  placeholder="Enter Password"
                  value={adminAdd.password}
                  onChange={handleInputs}
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  name="cpassword"
                  className={`${styles.add_admin_field} form-control`}
                  placeholder="Confirm Password"
                  value={adminAdd.cpassword}
                  onChange={handleInputs}
                />
              </div>

              <div className="mb-3">
                <button
                  onClick={submitAdmin}
                  className={`${styles.submit_admin_btn}`}
                >
                  Add Admin
                </button>
              </div>
            </div>

        
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAdmin;
