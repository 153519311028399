import { useContext, useState, useEffect } from "react";
import MainChart from "../../components/MainCharts/MainChart";
import LineAreaChart from "../../components/MainCharts/LineAreaChart";
import ChartPie from "../../components/MainCharts/ChartPie";
import styles from "./dashboard.module.css";
import myContext from "../../../context/context";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";



const Dashboard = () => {

  const [property, setProperty] = useState([]);
  const { dclose } = useContext(myContext);

    

   const getAllProperties = async ()=>{
    try {
      const res = await axios.get(`${API_BASE_URL}/properties/all-properties`);
      setProperty(res.data);
    }catch (error) {
      console.log(error, 'unable to handle get property on dashboard..');
    }
   }

   useEffect(()=>{
      getAllProperties();
   }, [])
  


  return (
    <>
    <DSidebar />
    <TopNav/>
    <div className={dclose ? `${styles.DashboardFull}` :  `${styles.Dashboard} pb-5`}>
      <div className="container-fluid">
        <div className="row mx-auto mt-3">
          <CountBoxes/>
        </div>
        <MainChart property={property}/>
        <div className="row mt-3">
        <LineAreaChart property={property} />
             <ChartPie property={property} />
        </div>
      </div>
    </div>
    </>

  );
};


export default Dashboard;