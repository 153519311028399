import React, { useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./addmanager.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";



function AddManager() {
  
  const { dclose } = useContext(myContext);
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [managerAdd, setManagerAdd] = useState({
    username: "",
    email: "",
    password: "",
    cpassword: "",
    role: "manager",
  });


  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);

    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // validation
  const formValidation = () => {
    if (
      managerAdd.username &&
      managerAdd.email &&
      managerAdd.password &&
      managerAdd.cpassword
    ) {
      if (managerAdd.password === managerAdd.cpassword) {
        const passwordRegex =
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        var emailRegex =/^\w+[\w.-]*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

        if (emailRegex.test(managerAdd.email)) {
          if (passwordRegex.test(managerAdd.password)) {
            return true;
          } else {
            setSuccessAlert(false);
            alterValidationMessage(
              "Password contain 6 to 16 valid characters and at least one number and on special character!"
            );
          }
        } else {
          setSuccessAlert(false);
          alterValidationMessage("Email is not valid");
        }
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Passwords Not Match!");
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };


  
  const submitManager = async () => {
    if (formValidation()) {
    try {
      await axios
        .post(`${API_BASE_URL}/auth/register`, {
          username: managerAdd.username,
          email: managerAdd.email,
          password: managerAdd.password,
          role: "manager",
        })
        .then((res) => {
          if (res.status === 200) {
            setSuccessAlert(true);
            alterValidationMessage("Successfully Added!");
            setManagerAdd({
              username: "",
              email: "",
              password: "",
              cpassword: "",
              role: "manager",
            }); 
          }
        });
    } catch (error) {
      console.log(error);
      setSuccessAlert(false);
      alterValidationMessage("Unable to Add User. retry or try later");
    }
  }
  };

  const handleInputs = (e) => {
    setManagerAdd({ ...managerAdd, [e.target.name]: e.target.value });
  };
 
  return (
    <>
     {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
              <CountBoxes/>
          </div>

          

          <div className="row mt-3">
            <h1 className="text-center mt-5">Add Manager</h1>
            {/* xxxxxxx Add New Manager xxxxxxxxxxxx */}
            <div className={`${styles.add_manager_form}`}>
              <div className="mb-3">
                <input
                  type="text"
                  name="username"
                  className={`${styles.add_manager_field} form-control`}
                  placeholder="Enter Manager Name"
                  value={managerAdd.username}
                  onChange={handleInputs}
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  name="email"
                  className={`${styles.add_manager_field} form-control`}
                  placeholder="Enter Manager Email"
                  value={managerAdd.email}
                  onChange={handleInputs}
                />
              </div>

              <div className="mb-3">
                <input
                  type="password"
                  name="password"
                  className={`${styles.add_manager_field} form-control`}
                  placeholder="Enter Password"
                  value={managerAdd.password}
                  onChange={handleInputs}
                />
              </div>

              <div className="mb-3">
                <input
                  type="password"
                  name="cpassword"
                  className={`${styles.add_manager_field} form-control`}
                  placeholder="Confirm Password"
                  value={managerAdd.cpassword}
                  onChange={handleInputs}
                />
              </div>

              <div className="mb-3">
                <button
                  onClick={submitManager}
                  className={`${styles.submit_manager_btn}`}
                >
                  Add Manager
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddManager;
