import Home from "../pages/Home/Home";
import Signup from "../auth/Signup";
import Offers from "../pages/offers/Offers";
import Comps from "../pages/comps/Comps";
import Sale from "../pages/sale/Sale";
import Probate from "../pages/probate/Probate";
import CourtConfirmation from "../pages/courtconfirmation/CourtConfirmation";
import Service from "../pages/service/Service";
import Contact from "../pages/contact/Contact";
import Login from "../auth/Login";
import PassReset from "../auth/PassReset";
import UploadForm from "../Testingfiles/Test";
import Dashboard from "../dashboard/dashboardpages/Dashboardmain/Dashboard";
import AddAdmin from "../dashboard/dashboardpages/Admin/AddAdmin";
import AddManager from "../dashboard/dashboardpages/Manager/AddManager";
import AddUser from "../dashboard/dashboardpages/User/AddUser";
import Properties from "../pages/Properties/Properties";
import AddProperty from "../dashboard/dashboardpages/AddProperty/AddProperty";
import ListedUsers from "../dashboard/dashboardpages/ListedUsers/ListedUsers";
import ListedProperties from "../dashboard/dashboardpages/ListedProperties/ListedProperties";
import UpdateProperty from "../dashboard/dashboardpages/UpdateProperty/UpdateProperty";
import SingleProperty from "../pages/singleproperty/SingleProperty";
import DeleteProperty from "../dashboard/dashboardpages/DeleteProperty/DeleteProperty";
import UpdateManager from "../dashboard/dashboardpages/UpdateManager/UpdateManager";
import UpdateUser from "../dashboard/dashboardpages/UpdateUser/UpdateUser";
import BlockUser from "../dashboard/dashboardpages/BlockUser/BlockUser";
import UnblockUser from "../dashboard/dashboardpages/UnblockUser/UnblockUser";
import AllBids from "../dashboard/dashboardpages/Bids/Bids";
import AllComments from "../dashboard/dashboardpages/Comments/AllComments";
import UserBids from "../dashboard/dashboardpages/UserBids/UserBids";
import AllBookings from "../dashboard/dashboardpages/AllBooking/AllBookings";
import CompletedBookings from "../dashboard/dashboardpages/CompletedBookings/CompletedBookings";
import TimeSlotsAddAndGet from "../dashboard/dashboardpages/TimeSlotsAddAndGet/TimeSlotsAddAndGet";
import BookingCompleteList from "../dashboard/dashboardpages/BookingCompleteList/BookingCompleteList";
import PurchaseListing from "../dashboard/dashboardpages/PurhcaseListing/PurchaseListing";
import BookingExpire from "../dashboard/components/Bookingexpire/BookingExpire";
import Otp from "../auth/Otp";
import UserList from "../dashboard/dashboardpages/UserList/UserList";
import ManagerList from "../dashboard/dashboardpages/ManagerList/ManagerList";
import AdminList from "../dashboard/dashboardpages/AdminList/AdminList";
import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import NotFound from "../pages/NotFound/NotFound";
import ProtectedPage from "./ProtectedPage";
import AdminProtection from "./AdminProtection";
import AddPersonalRep from "../dashboard/dashboardpages/AddPersonalRep/AddPersonalRep";
import PersonalRepList from "../dashboard/dashboardpages/PersonalRepList/PersonalRepList";
import AgentList from "../dashboard/dashboardpages/AgentList/AgentList";
import AddAgent from "../dashboard/dashboardpages/AddAgent/AddAgent";
import AddTeamAssociates from "../dashboard/dashboardpages/AddTeamAssociates/AddTeamAssociates";

import TeamAssociatesList from "../dashboard/dashboardpages/TeamAssociatesList/TeamAssociatesList";
import AddAttorney from "../dashboard/dashboardpages/AddAttorney/AddAttorney";
import AttorneyList from "../dashboard/dashboardpages/AttorneyList/AttorneyList";
import AssignedTeamAssociate from "../dashboard/dashboardpages/AddTeamAssociates/AssignedTeamAssociates";
import AssignedBooking from "../dashboard/dashboardpages/AllBooking/AssignedBooking";
import AssignedCompletedBookings from "../dashboard/dashboardpages/CompletedBookings/AssignedCompleteBooking";
import AssignedPersonalRep from "../dashboard/dashboardpages/PersonalRepList/AssignedPersoanlReps";
import AssingedPersonalRep from "../dashboard/dashboardpages/ListedProperties/AssingedPersonalRep";
import AssignedAttorney from "../dashboard/dashboardpages/AttorneyList/AssignedAttorney";
import AssignedAgentProperties from "../dashboard/dashboardpages/ListedProperties/AssignedAgentProperties";
import PurchaseListingAttorney from "../dashboard/dashboardpages/PurhcaseListing/PurchaseListingAttorney";
import AssignedAttorneyProperties from "../dashboard/dashboardpages/ListedProperties/AssignedAttorneyProperties";
import SuperAdminProtection from './SuperAdminProtection';
import ExceptUserProtection from "./ExceptUserProtection";
import AllBookingProtection from "./AllBookingProtection";
import CompleteBookingProtection from "./CompleteBookingProtection";
import AttorneyOnlyProtection from "./AttorneyOnlyProtection";
import PersonalRepsOnlyProtection from "./PersonalRepsOnlyProtection";
import TeamAssociateOnlyProtection from "./TeamAssociateOnlyProtection";
import UserConcernOnlyProtection from "./UserConcernOnlyProtection";
import TeamAssociateConcernOnlyProtection from "./TeamAssociateConcernOnlyProtection";
import Activate from "../pages/NotFound/Activate";
import AgentOnlyProtection from "./AgentOnlyProtection";
import AssignedAgent from "../dashboard/dashboardpages/AgentList/AssignedAgent";


// for unregister user...
const routes = createBrowserRouter(
[

  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/reset-password",
    element: <PassReset />,
  },
  {
    path: "/opt-verification",
    element: <Otp />,
  },
  {
    path: "/sale",
    element: <Sale />,
  },
  {
    path: "/probate",
    element: <Probate/> ,
  },
  {
    path: "/courtconfirmation",
    element: <CourtConfirmation />,
  },
  {
    path: "/services",
    element: <Service />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/testing",
    element: <UploadForm />,
  },
  {
    path: "/all-properties",
    element: <Properties />,
  },
  {
    path: "/properties/property-details/:id",
    element: <SingleProperty />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
 
  
]);

// for register users...
const protectedRoutes = createBrowserRouter(
  [

    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/reset-password",
      element: <PassReset />,
    },
    {
      path: "/opt-verification",
      element: <Otp />,
    },
    {
      path: "/offers",
      element: <Offers />,
    },
    {
      path: "/comps",
      element:<AdminProtection Page={Comps} /> ,
    },
    {
      path: "/sale",
      element: <Sale />,
    },
    {
      path: "/probate",
      element: <Probate/> ,
    },
    {
      path: "/courtconfirmation",
      element: <CourtConfirmation />,
    },
    {
      path: "/services",
      element: <Service />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/testing",
      element: <UploadForm />,
    },
    {
      path: "/all-properties",
      element:  <Properties />,
    },
    {
      path: "/properties/property-details/:id",
      element: <SingleProperty />,
    },
    {
      path: "/dashboard",
      element:<ProtectedPage Page={Dashboard} />,
    },
    {
      path: "/dashboard/add-admin",
      element:<SuperAdminProtection Page={AddAdmin} />,
    },
    {
      path: "/dashboard/admins-list",
      element:<SuperAdminProtection Page={AdminList} />,
    },
    {
      path: "/dashboard/add-manager",
      element:<AdminProtection Page={AddManager} />,
    },
    {
      path: "/dashboard/add-user",
      element: <ProtectedPage Page={AddUser} />,
    },
    {
      path: "/dashboard/add-property",
      element: <ProtectedPage Page={AddProperty} />,
    },
    {
      path: "/dashboard/users-list",
      element: <ProtectedPage Page={UserList} />,
    },
    {
      path: "/dashboard/add-property",
      element:<ProtectedPage Page={AddProperty} />,
    },
    {
      path: "/dashboard/all-listed-users",
      element:<ProtectedPage Page={ListedUsers} /> ,
    },
    {
      path: "/dashboard/all-listed-properties",
      element: <ExceptUserProtection Page={ListedProperties} />,
    },
    {
      path: "/dashboard/assigned-personalrep-property",
      element: <PersonalRepsOnlyProtection Page={AssingedPersonalRep} />,
    },
    {
      path: "/dashboard/assigned-attorney-property",
      element: <AttorneyOnlyProtection Page={AssignedAttorneyProperties} />,
    },
    {
      path: "/dashboard/assigned-agent-property",
      element: <AgentOnlyProtection Page={AssignedAgentProperties} />,
    },
    {
      path: "/dashboard/update-properties",
      element: <TeamAssociateConcernOnlyProtection Page={UpdateProperty} />,
    },
    {
      path: "/dashboard/delete-properties",
      element: <ProtectedPage Page={DeleteProperty} />,
    },
    {
      path: "/dashboard/update-manager",
      element:<AdminProtection Page={UpdateManager} />,
    },
    {
      path: "/dashboard/managers-list",
      element:<AdminProtection Page={ManagerList} />,
    },
    {
      path: "/dashboard/update-user",
      element: <ProtectedPage Page={UpdateUser} />,
    },
    {
      path: "/dashboard/block-user",
      element: <ProtectedPage Page={BlockUser} /> ,
    },
    {
      path: "/dashboard/unblock-user",
      element: <ProtectedPage Page={UnblockUser} />,
    },
    {
      path: "/dashboard/all-bids",
      element:<ExceptUserProtection Page={AllBids} /> ,
    },
    {
      path: "/dashboard/all-comments",
      element: <UserConcernOnlyProtection Page={AllComments} />,
    },
    {
      path: "/dashboard/user-bids",
      element: <ProtectedPage Page={UserBids} />,
    },
    {
      path: "/dashboard/all-bookings",
      element:<AllBookingProtection Page={AllBookings} />,
    },
    {
      path: "/dashboard/all-bookings-assigned",
      element:<TeamAssociateOnlyProtection Page={AssignedBooking} />,
    },
    {
      path: "/dashboard/update-bookings",
      element: <ProtectedPage Page={CompletedBookings} />,
    },
    {
      path: "/dashboard/update-bookings-assigned",
      element: <TeamAssociateOnlyProtection Page={AssignedCompletedBookings} />,
    },
    {
      path: "/dashboard/all-timeSlots",
      element: <ProtectedPage Page={TimeSlotsAddAndGet} />,
    },
    {
      path: "/dashboard/completed-bookings",
      element: <CompleteBookingProtection Page={BookingCompleteList} />,
    },
    {
      path: "/dashboard/purchased-properties",
      element: <UserConcernOnlyProtection Page={PurchaseListing} /> ,
    },
    {
      path: "/dashboard/purchased-properties-attorney",
      element: <AttorneyOnlyProtection Page={PurchaseListingAttorney} /> ,
    },
    {
      path:"/dashboard/add-personal-rep",
      element: <ProtectedPage Page={AddPersonalRep} />
    },
    {
      path:"/dashboard/personal-rep-list",
      element: <ProtectedPage Page={PersonalRepList} /> 
    },
    {
      path:"/dashboard/add-agents",
      element: <ProtectedPage Page={AddAgent} />
    },
    {
      path:"/dashboard/agent-list",
      element:<ProtectedPage Page={AgentList} />
    },
    {
      path:"/dashboard/add-team-associates",
      element: <ProtectedPage Page={AddTeamAssociates} />
    },
    {
      path:"/dashboard/team-associates-listing",
      element: <ProtectedPage Page={TeamAssociatesList} />
    },
    {
      path:"/dashboard/add-attorneys",
      element: <ProtectedPage Page={AddAttorney} />
    },
    {
      path:"/dashboard/attorney-list",
      element: <ProtectedPage Page={AttorneyList} />
    },
    {
      path:"/dashboard/assigned-teamassociate-list",
      element: <ProtectedPage Page={AssignedTeamAssociate} />
    },
    {
      path:"/dashboard/assigned-attorney-list",
      element: <ProtectedPage Page={AssignedAttorney} />
    },
    {
      path:"/dashboard/assigned-personalreps-list",
      element: <ProtectedPage Page={AssignedPersonalRep} />
    },
    {
      path:"/dashboard/assigned-agent-list",
      element: <ProtectedPage Page={AssignedAgent} />
    },
    {
      path: "/bookingexpire",
      element: <BookingExpire />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: "/activation",
      element: <Activate />,
    },
    
  ]
);




export {routes, protectedRoutes};



