import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";

const PurchasePropModal = ({ purchasedModal, setPurchasedModal, status }) => {
  const [upStatus, setUpStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${API_BASE_URL}/purchase/${status.propertyID}/update/${status._id}`,
        {
          status: upStatus,
        }
      );

      alert("Success");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="modal_wrapper text-center">
        <div className="modal-form">
          <span
            className="close-modal"
            onClick={() => setPurchasedModal(false)}
          >
            X
          </span>
          <form onSubmit={handleSubmit}>
            <div className="form-input-container">
              <label>Status:</label>

              <select onChange={(e) => setUpStatus(e.target.value)}>
                <option value="unsold">unsold</option>
                <option value="sold">sold</option>
              </select>
            </div>

            <div>
              <button className="modal-btn" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PurchasePropModal;
