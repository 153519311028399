import React, { useState } from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import { API_BASE_URL } from "../config";

const UploadForm = () => {
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('address', address);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('image', image);

    // Append each gallery image file to the form data
    galleryImages.forEach((file) => {
      formData.append('galleryImages', file);
    });

    try {
      const response = await axios.post(`${API_BASE_URL}/properties/create-property`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      // Reset form after successful submission
      setTitle('');
      setAddress('');
      setPrice('');
      setDescription('');
      setImage(null);
      setGalleryImages([]);
    } catch (err) {
      console.error(err);
    }
  };

  const handleFeatureImageDrop = (acceptedFiles) => {
    // Only keep the first file selected
    setImage(acceptedFiles[0]);
  };

  const handleGalleryImageDrop = (acceptedFiles) => {
    // Keep track of all selected files
    setGalleryImages([...galleryImages, ...acceptedFiles]);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div>
        <label>Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Address:</label>
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Price:</label>
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Image:</label>
        <Dropzone onDrop={handleFeatureImageDrop}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} multiple={false} />
                <p>Drag 'n' drop an image here, or click to select a file</p>
              </div>
              {image && (
                <div>
                  <h4>Selected image:</h4>
                  <img
                    src={URL.createObjectURL(image)}
                    alt="preview"
                    height="200"
                  />
                </div>
              )}
            </section>
          )}
        </Dropzone>
      </div>
      <div>
        <label>Gallery Image:</label>
        <Dropzone onDrop={handleGalleryImageDrop}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop an image here, or click to select a file</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <button type="submit">Add Property</button>
    </form>

  );
};

export default UploadForm;
