import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import styles from "./bidingmodal.module.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const BidingModal = ({ setBidingModal, id, setMyres }) => {

  
  const navigate = useNavigate();
  const user = useSelector((state) => state);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [bidingPrice, setBidingPrice] = useState(null);
  const [validationAlert, setValidationAlert] = useState(false);

  // alter validation error...
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // Authenticate User then Add New Bid
  const handleSubmit = async () => {
    // authenticate user...
    if (user.isLoggedIn) {
      // check bid price validation...
    
      if (bidingPrice && Number(bidingPrice) !== 0 && bidingPrice > 0) {
        try {
          const res = await axios.post(
            `${API_BASE_URL}/properties/property/${id}/create-bid`,
            {
              user_id: user?.data?._id,
              bids: bidingPrice,
              email: user?.data?.email,
              username: user?.data?.username,
            }
          );
          setMyres(res.data);
          setBidingModal(false);
        } catch (err) {
          console.log(err, "not posted biding price");
          setSuccessAlert(false);
          alterValidationMessage("Unable to Add Your Bid, try later or retry!");
        }
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Plz Submit Your Biding Price");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className={styles.biding_modal_wrapper}>
        {validationAlert ? (
          <div
            className={
              successAlert
                ? "alert alert-success validation-alert d-flex justify-content-between"
                : "alert alert-danger validation-alert d-flex justify-content-between"
            }
            role="alert"
            onClick={() => setValidationAlert(false)}
          >
            <span>{alertMessage}</span>
            <span className="close-alert">X</span>
          </div>
        ) : null}
        <div className={styles.biding_modal_box}>
          <span
            className={styles.close_modal}
            onClick={() => setBidingModal(false)}
          >
            X
          </span>
          <h1>ADD YOUR BID</h1>
          <div>
            <Form.Group className={`mb-3`} controlId="formBasicEmail">
              <Form.Control
                className={`${styles.signupField}`}
                type="number"
                value={bidingPrice}
                placeholder="Enter your amount"
                name="phone"
                onChange={(e) => setBidingPrice(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="text-center">
            <Button
              variant="primary"
              className={`${styles.btn}`}
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidingModal;
