import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import TopNav from "../../components/dashbaordHeader/TopNav";
import React, { useEffect, useState } from "react";
import myContext from "../../../context/context";
import styles from "./updateproperty.module.css";
import { API_BASE_URL } from "../../../config";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { useContext } from "react";
import ModalX from "./ModalX";
import axios from "axios";


const UpdateProperty = () => {
  const { dclose } = useContext(myContext);
  const [showModal, setShowModal] = useState(false);
  const [propertyObj, setProperyObj] = useState({});
  const [updateProperties, setUpdatedProperties] = useState([]);
  const userData = useSelector((state) => state.data);
  const [loader, setLoader] = useState(false);

  const handleModal = (item) => {
    setShowModal(true);
    setProperyObj(item);
  };

  const getUpdateProperties = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/properties/all-properties`)
      .then((res) => {
        setUpdatedProperties(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUpdateProperties();
  }, []);

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="row">
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Property Title</th>
                    <th>Address</th>
                    <th>Price</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="5" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : userData?.role === "team associates" ? (
                    updateProperties
                      ?.filter((item) => item?.assignedTo[0] === userData?._id)
                      ?.map((item, i) => {
                        return (
                          <>
                            <tr key={item._id}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item.title}</td>
                              <td>{item.address}</td>
                              <td>{item.price}</td>
                              <td className="text-center">
                                <Button
                                  variant="dark"
                                  className={styles.table_btns}
                                  onClick={() => handleModal(item)}
                                >
                                  Update
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                  ) : (
                    updateProperties.map((item, i) => {
                      return (
                        <>
                          <tr key={item._id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.address}</td>
                            <td>{item.price}</td>
                            <td className="text-center">
                              <Button
                                variant="dark"
                                className={styles.table_btns}
                                onClick={() => handleModal(item)}
                              >
                                Update
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <ModalX setShowModal={setShowModal} propertyObj={propertyObj} />
      ) : (
        ""
      )}
    </>
  );
};

export default UpdateProperty;
