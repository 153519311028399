import React from 'react'

const Service = () => {
  return (
    <div>
      <h1>To be discussed.</h1>
    </div>
  )
}


export default Service
