import styles from "./Pageleft.module.css";

const PageleftBars = ({ dark }) => {
  return (
    <>
      <div className={`${styles.line_scroll}`}>
        <div className={styles.line_contain}>
          <a
            href="#hero"
            title="Top"
            className={dark ? `${styles.bar_dark}` : `${styles.bar}`}
          ></a>
          <a
            href="#about"
            title="About"
            className={dark ? `${styles.bar_dark}` : `${styles.bar}`}
          ></a>
          <a
            href="#services"
            title="Services"
            className={dark ? `${styles.bar_dark}` : `${styles.bar}`}
          >
            {" "}
          </a>
          <a
            href="#gallery1"
            title="Gallery"
            className={dark ? `${styles.bar_dark}` : `${styles.bar}`}
          ></a>
          <a
            href="#chooseus"
            title="Why choose us?"
            className={dark ? `${styles.bar_dark}` : `${styles.bar}`}
          ></a>
          <a
            href="#gallery2"
            title="Explore gallery"
            className={dark ? `${styles.bar_dark}` : `${styles.bar}`}
          ></a>
          <a
            href="#testimonials"
            title="Testimonials"
            className={dark ? `${styles.bar_dark}` : `${styles.bar}`}
          ></a>
        </div>
      </div>
    </>
  );
};

export default PageleftBars;
