import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config";
import axios from "axios";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    data: null,
    error: null,
  },
  reducers: {
    Success: (state, action) => {
      state.isLoggedIn = true;
      state.data = action.payload;
      state.error = null;
    },
    Failure: (state, action) => {
      state.isLoggedIn = false;
      state.data = null;
      state.error = action.payload;
    },
    Logout: (state) => {
      state.isLoggedIn = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.data = action.payload.user;
      state.error = null;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.data = null;
      state.error = "Internal Server Error!";
    });

    // builder.addCase(logout.fulfilled, (state, action) => {
    //   state.isLoggedIn = false;
    //   state.data = null;
    //   state.error = null;
    // });
    // builder.addCase(register.fulfilled, (state, action) => {
    //   state.isLoggedIn = true;
    //   state.data = action.payload.user;
    //   state.error = null;
    // });
    // builder.addCase(register.rejected, (state, action) => {
    //   state.isLoggedIn = false;
    //   state.data = null;
    //   state.error = "Unable TO Signup";
    // });
  },
});


export const { Success, Failure, Logout } = userSlice.actions;
export default userSlice.reducer;

// export const register = createAsyncThunk(
//   "user/register",
//   async (credentials) => {
//     try {
//       const response = await axios.post(`${API_BASE_URL}/auth/register`, {
//         username: credentials.username,
//         email: credentials.email,
//         password: credentials.password,
//       });
//       return response.data;
//     } catch (error) {
//       throw error.response.data;
//     }
//   }
// );

export const login = createAsyncThunk(
  "user/login",
  async (loginCredentials) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/login`,
        loginCredentials
      ); 
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

// export const logout = createAsyncThunk(
//   "user/logout",
//   async (logoutCredentials) => {
//     try {
//     } catch (error) {
//       throw error.response.data;
//     }
//   }
// );
