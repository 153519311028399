import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../config";
import styles from "./commentmodal.module.css";

const CommentModal = (props) => {
  // new comment...
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [newComment, setNewComment] = useState(props?.data?.comment);
  const propertyId = props?.id;
  const commentId = props?.data?._id;

  // get user_id for update comment
  const user = useSelector((state) => state.data);

  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // comment updated...
  const updateComment = async () => {
    if (newComment) {
      try {
        const res = await axios.patch(
          `${API_BASE_URL}/properties/property/${propertyId}/update-comment/${commentId}`,
          {
            user_id: user?._id,
            comment: newComment,
            email: user?.email,
            username: user?.username,
          }
        );
        props.onHide();
        props.setComments(res.data);
      } catch (error) {
        console.log(error, "Unalbe to Handle Update Comment");
        setSuccessAlert(false);
        alterValidationMessage(
          "Unalbe to Handle Update Comment, try later or retry"
        );
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("Plz Write your comment!");
    }
  };

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className={styles.modal_background}>
          <Modal.Title id="contained-modal-title-vcenter">
            UPDATE COMMENT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_background}>
          <div className="booking_container">
            <input
              type="text"
              className={styles.comment_modal_field}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.modal_background}>
          <Button className="btn-dark" onClick={() => updateComment()}>
            Update
          </Button>
          <Button className="btn-dark" onClick={props?.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommentModal;
