import styles from "./Header.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";
import myContext from "../../context/context";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../Redux/reducers/userSlice";

   
const Sidebar = () => {

  const { close, closeSidebar } = useContext(myContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state);

  const handleLogout = () => {
    dispatch(Logout());
    localStorage.clear();
    navigate('/')
  };

  return (
    <>
      <section
        className={
          close ? `${styles.header_sidebar_close}` : `${styles.header_sidebar}`
        }
      >
        <span className={styles.close_sidebar} onClick={closeSidebar}>
          X
        </span>
        <div className={styles.navigations_links_container}>
          <ul className={styles.navigation_list}>
            <li className={styles.navigation_list_li}>
              {" "}
              <NavLink
                className={styles.navlinks}
                onClick={closeSidebar}
                to="/"
              >
                {" "}
                Home
              </NavLink>
            </li>
            <li className={styles.navigation_list_li}>
              {" "}
              <NavLink
                className={styles.navlinks}
                onClick={closeSidebar}
                to="/all-properties"
              >
                Property
              </NavLink>
            </li>
            {userData.isLoggedIn ?
            <li className={styles.navigation_list_li}>
              {" "}
              <NavLink
                className={styles.navlinks}
                onClick={closeSidebar}
                to="/offers"
              >
                {" "}
                Offers
              </NavLink>
            </li> : "" }
            {userData.isLoggedIn && userData?.data?.role === "admin" ? (
              <li className={styles.navigation_list_li}>
                {" "}
                <NavLink
                  className={styles.navlinks}
                  onClick={closeSidebar}
                  to="/comps"
                >
                  {" "}
                  Comps
                </NavLink>
              </li>
            ) : (
              ""
            )}
            <li className={styles.navigation_list_li}>
              {" "}
              <NavLink
                className={styles.navlinks}
                onClick={closeSidebar}
                to="/sale"
              >
                {" "}
                Sale
              </NavLink>
            </li>

            <li className={styles.navigation_list_li}>
              {" "}
              <NavLink
                className={styles.navlinks}
                onClick={closeSidebar}
                to="/probate"
              >
                {" "}
                Probate
              </NavLink>
            </li>
            <li className={styles.navigation_list_li}>
              {" "}
              <NavLink
                className={styles.navlinks}
                onClick={closeSidebar}
                to="/courtconfirmation"
              >
                {" "}
                Court Confirmation
              </NavLink>
            </li>
            <li className={styles.navigation_list_li}>
              {" "}
              <NavLink
                className={styles.navlinks}
                onClick={closeSidebar}
                to="/services"
              >
                {" "}
                Services
              </NavLink>
            </li>
            {userData.isLoggedIn ? (
              <li className={styles.navigation_list_li}>
                {" "}
                <NavLink
                  className={styles.navlinks}
                  onClick={closeSidebar}
                  to={ userData?.data?.role == "agent" ? (userData?.data?.activate) ? "/dashboard" : "/activation" : "/dashboard" }  
                >
                  {" "}
                  Dashboard
                </NavLink>
              </li>
            ) : (
              ""
            )}
            <li className={styles.navigation_list_li}>
              {" "}
              <NavLink
                className={styles.navlinks}
                onClick={closeSidebar}
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
            {userData.isLoggedIn ? (
              <li className={styles.navigation_list_li}>
                {" "}
                <NavLink
                  className={styles.navlinks}
                  onClick={handleLogout}
                  to="/"
                >
                  Logout
                </NavLink>
              </li>
            ) : (
              <>
                <li className={styles.navigation_list_li}>
                  {" "}
                  <NavLink
                    className={styles.navlinks}
                    onClick={closeSidebar}
                    to="/login"
                  >
                    Login
                  </NavLink>
                </li>
                <li className={styles.navigation_list_li}>
                  {" "}
                  <NavLink
                    className={styles.navlinks}
                    onClick={closeSidebar}
                    to="/signup"
                  >
                    Signup
                  </NavLink>
                </li>
              </>
            )}
            <li
              className={`${styles.navigation_list_li} d-flex flex-column mt-5`}
            >
              <span>London Oxford Street, 012 United Kingdom CA, 4554</span>
              <span>
                <Link to="">Business@realstate.net</Link>
              </span>
              <span>
                <Link to="">+125 0545 45454</Link>
              </span>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
