import React, { useEffect, useState } from "react";
import { Carousel, CarouselItem } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import styles from "./properties.module.css";
import Header from "../../componenets/Header/Header";


function Properties() {

  const [query, setQuery] = useState([]);
  const [property, setProperty] = useState([]);
  const [minPriceOptions, setMinPriceOptions] = useState("");
  const [maxPriceOptions, setMaxPriceOptions] = useState("");

  // get all property...
  const getAllProperties = async () => {
    try {
      await axios
        .get(`${API_BASE_URL}/properties/all-properties`)
        .then((res) => {
          let data = res?.data?.filter((item)=> item?.approved == true)
          setProperty(data);
          setQuery(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProperties();
  }, []);

  // Handle Property on Search
  const handleSearchChange = (event) => {
    const searchValue = event.target.value;

    if (searchValue === "") {
      setQuery(property);
    } else {
      const filteredData = property.filter((property) =>
        property.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      setQuery(filteredData);
    }
  };

  // Filter Unsold properties.
  const Unsold = query.filter((props) => {
    if (minPriceOptions === "" && maxPriceOptions === "") {
      return props.status === "sold" || props.status === "unsold";
    } else if (minPriceOptions !== "" && maxPriceOptions === "") {
      return (
        props.status === "unsold" &&
        parseInt(props.price) >= parseInt(minPriceOptions)
      );
    } else if (minPriceOptions === "" && maxPriceOptions !== "") {
      return (
        props.status === "unsold" &&
        parseInt(props.price) <= parseInt(maxPriceOptions)
      );
    } else {
      return (
        props.status === "unsold" &&
        parseInt(props.price) >= parseInt(minPriceOptions) &&
        parseInt(props.price) <= parseInt(maxPriceOptions)
      );
    }
  });

  return (
    <>
      <div>
        <Header />
      </div>

      <div className={`container-fluid ${styles.container_bg} px-lg-5 d-block`}>
        <div className="">
          <div className="d-block  col-lg-12 p-0">
            <div className={styles.product_filtration}>
              <div className={styles.product_filtration_search}>
                <img alt="aa" src="./assets/images/property/search-icon.png" />
                <input
                  type="text"
                  placeholder="Search Here"
                  onChange={handleSearchChange}
                />
              </div>

              {/* Minimum Price Filters */}

              <div>
                <select
                  defaultValue={"DEFAULT"}
                  onChange={(e) => setMinPriceOptions(e.target.value)}
                >
                  <option value="DEFAULT" disabled>
                    Select Min Price
                  </option>
                  <option value="5000">Min Price: $5K</option>
                  <option value="4000">Min Price: $4K</option>
                  <option value="3000">Min Price: $3K</option>
                  <option value="2000">Min Price: $2K</option>
                  <option value="1000">Min Price: $1K</option>
                </select>
              </div>

              <div>
                <select
                  defaultValue={"DEFAULT"}
                  onChange={(e) => setMaxPriceOptions(e.target.value)}
                >
                  <option value="DEFAULT" disabled>
                    Select Max Price
                  </option>
                  <option value="5000">Max Price: $5K</option>
                  <option value="4000">Max Price: $4K</option>
                  <option value="3000">Max Price: $3K</option>
                  <option value="2000">Max Price: $2K</option>
                  <option value="1000">Max Price: $1K</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className={`col-lg-12 `}>
              <h1 className="text-center py-5">UnSold Properties</h1>
              <div className={`row`}>
                {Unsold && Unsold.length > 0 ? (
                  Unsold.map((data) => (
                    <div
                      className="col-lg-3 col-md-6 col-12 p-2"
                      key={data?._id}
                    >
                      <div className={styles.property_card}>
                        <div className={styles.image_section}>
                          <Carousel autoPlay={true} draggable={true}>
                            {data.gallery_images !== undefined &&
                            data.gallery_images !== null &&
                            data.gallery_images !== "" ? (
                              data.gallery_images.map((gallery_images, i) => (
                                <CarouselItem key={i}>
                                  <img
                                    className={styles.contentStyle}
                                    src={`${API_BASE_URL}/${gallery_images}`}
                                    alt="aa"
                                  />
                                </CarouselItem>
                              ))
                            ) : (
                              <h1>No Data To Show</h1>
                            )}
                          </Carousel>

                          <div className={styles.product_card_heart_hove2}>
                            <span className={styles.for_sale}>
                              {data.status}
                            </span>
                            <span className={styles.featured}>Featured</span>
                          </div>
                        </div>
                        <Link to={`/properties/property-details/${data._id}`}>
                          <div className={styles.detail_section}>
                            <div className="d-flex align-items-center mb-2">
                              <span>{data.feature}</span>
                            </div>
                            <div>
                              <h5>{data.title}</h5>
                            </div>

                            <h3 className=" text-dark">${data.price}</h3>
                            <p className="m-0 text-dark">
                              {data.address.substring(0, 30)}...
                            </p>
                            <p className="m-0 text-dark">
                              {data.description.substring(0, 80)} ....
                            </p>
                            <div className="d-flex align-items-center">
                              <span className="d-flex align-items-center">
                                <img
                                  src="./assets/images/property/bed.png"
                                  alt="aa"
                                  className="me-2"
                                />
                                {data.rooms} Bedrooms
                              </span>
                              <span className="d-flex align-items-center">
                                <img
                                  src="./assets/images/property/kitchen.png"
                                  alt="aa"
                                  className="me-1"
                                />
                                {data.kitchen} Kitchen
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <h3 className="text-white text-center"> No Data To Show </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Properties;
