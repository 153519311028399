import axios from "axios";
import { API_BASE_URL } from "../../../config";
import styles from "../../dashboardpages/Dashboardmain/dashboard.module.css";
import { useState, useEffect } from "react";


const CountBoxes = () => {

const [allUsers, setAllUsers] = useState([]);
const [propertyCount, setPropertyCount] = useState(0);
const [userCount, setUserCount] = useState(0);
const [attorneyCount, setAttorneyrCount] = useState(0);
const [managerCount, setManagerCount] = useState(0);

// get all properties...
const getAllProperties = async () => {
  try {
    const res = await axios.get(`${API_BASE_URL}/properties/all-properties`);
    setPropertyCount(res.data.length);
  } catch (error) {
    console.log(error, "unable to handle get property on dashboard..");
  }
};


// filter base on user types.
const filterUsers = () => {
  const filterUser = allUsers?.filter((props) => props.role === "user");
  const filterManager = allUsers?.filter((props) => props.role === "manager");
  const filterAttorney = allUsers?.filter((props) => props.role === "attorney");
  setUserCount(filterUser.length);
  setManagerCount(filterManager.length);
  setAttorneyrCount(filterAttorney.length);
};

// get all users.
const getAllTypesUser = async () => {
  try {
    const res = await axios.get(`${API_BASE_URL}/all-users`);
    setAllUsers(res.data);
  } catch (error) {
    console.log(error);
  }
};

useEffect(() => {
  getAllProperties();
  getAllTypesUser();
}, []);

// Call filterUsers whenever allUsers state changes
useEffect(() => {
  filterUsers();
}, [allUsers]);


  return <>
    <div className="col-lg-3 col-sm-6 col-12">
            <div className={`${styles.top_box} ${styles.top_box_1} m-2 mx-auto`} >

              <span>TOTAL PROPERTIES</span>
              <h3>{propertyCount}</h3>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className={`${styles.top_box} ${styles.top_box_2} m-2 mx-auto`}>
              <span>TOTAL USERS</span>
              <h3>{userCount}</h3>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className={`${styles.top_box} ${styles.top_box_3} m-2 mx-auto`}>
              <span>TOTAL MANAGERS</span>
              <h3>{managerCount}</h3>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className={`${styles.top_box} ${styles.top_box_4} m-2 mx-auto`}>
              <span>TOTAL ATTORNIES</span>
              <h3>{attorneyCount}</h3>
            </div>
          </div>
  </>;
};


export default CountBoxes;