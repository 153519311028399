import Container from "react-bootstrap/Container";
import styles from "./home.module.css";
import Header from "../../componenets/Header/Header";
import { Row, Col } from "react-bootstrap";
import { useContext} from "react";
import myContext from "../../context/context";
import PageRightNo from "../../componenets/Home/PageRightNo/PageRightNo";
import PageleftBars from "../../componenets/Home/PageleftBars/PageleftBars";
import CardSlider from "../../componenets/Home/Slider/CardSlider";
import GallerySlider from "../../componenets/GallerySlider/GallerySlider";
import Footer from "../../componenets/Footer/Footer";
import { FaStar } from "react-icons/fa";
import Slider from 'react-slick';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIosNew } from "react-icons/md";




function Home() {

  const { close } = useContext(myContext);


  const Testimonialsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoPlay: false,
    autoPlaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoPlay: false,
    autoPlaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  return (
    <>
    
      <Header isBlur={false} />           
      {/* Hero Section */}
      <Container fluid className={styles.hero_section} id="hero">
        <video
          src="./assets/videos/hero_background.mp4"
          className={styles.hero_background_video}
          autoPlay
          loop
          muted
        />
        <Row>
          <Col>
            <div
              className={
                close
                  ? `${styles.hero_content_container}`
                  : `${styles.hero_content_container_full}`
              }
            ></div>
            <div className={styles.hero_content}>
              <div className={styles.hero_headings}>
                <span>REAL STATE</span>
                <h1>THE KEY TOO</h1>
                <h1>YOUR HOME</h1>
              </div>
            </div>

            <PageleftBars dark={false} />

            <div className={`${styles.page_right_container}`}>
              <div className="row">
                <div className={`${styles.pageright_1} col-12  ps-5`}>
                  <h1>01</h1>
                  <span className={`${styles.heading}`}>INTRODUCTION</span>
                  <a href="#about" className={`${styles.colorText}`}>
                    <span className={`${styles.scroll_down}`}>scroll down</span>
                  </a>
                </div>
              </div>
            </div>

           
          </Col>
        </Row>
      </Container>

      {/* About us */}
      <div
        className={`container-fluid ${styles.about_component_wrapper}`}
        id="about"
      >
        <div className="row">
          <div className="col-xl-12 col-8 mx-auto">
            <div className={`container ${styles.about_component}`}>
              <div className="row">
                <div
                  className={`col-lg-6 order-lg-1 
                   order-2 mx-auto  ${styles.about_comp_content}`}
                >
                  <h5 className="mb-5 mt-lg-0 mt-5 text-lg-start text-center">
                    {" "}
                    About us
                  </h5>
                  <h3 className="mb-5 text-lg-start text-center">
                    We are best and most trusted real estat agent
                  </h3>
                  <div className={`${styles.about_list}`}>
                    <h6 className="mb-4">BUY A HOME</h6>
                    <p>
                      Browse million of properties in your city, sace your
                      favorites and set up search alert
                    </p>
                  </div>
                  <div className={`${styles.about_list}`}>
                    <h6 className="mb-4">RENT A HOME</h6>
                    <p>
                      Communication directly with advertiser. if all goes well.
                      one of these verified users.
                    </p>
                  </div>
                  <div className={`${styles.about_list}`}>
                    <h6 className="mb-4">SELL A HOME</h6>
                    <p>
                      Find your dream house? you just need to a few action and
                      you can start move to your house
                    </p>
                  </div>
                </div>
                <div
                  className={`col-lg-6 order-lg-2 order-1 ${styles.about_comp_img}`}
                >
                  <figure
                    className={` text-lg-end text-center ${styles.about_img}`}
                  >
                    <img
                      src="./assets/images/home/aboutcomponentimg.png"
                      alt=""
                    />
                 
                  </figure>
                  {/* */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageRightNo
          no={"02"}
          heading={"ABOUT US"}
          pageId={"#services"}
          dark={false}
        />
        <PageleftBars dark={false} />
      </div>
      {/* Services */}
      <div
        className={`container-fluid ${styles.services_wrapper}`}
        id="services"
      >
        <div className="row">
          <div className=" col-9 mx-auto">
            <div className={`container ${styles.services_headings}`}>
              <h5 className="mb-5 mt-5 pt-5 text-lg-start text-center">
                {" "}
                OUR SERVICES
              </h5>
              <h3 className="mb-5 text-lg-start text-center">
                WE PROVIDE THE BEST QUALITY PRODUCT FOR YOU
              </h3>
              <div className="row">
                <div className="col-xxl-2 mx-auto">
                  <div className={`${styles.service_slider_tabs}`}>
                    <span className="my-3 mx-xxl-0 mx-3">PROPERTY</span>
                    <span className="my-3 mx-xxl-0 mx-3">SHOWING</span>
                    <span className="my-3 mx-xxl-0 mx-3">OFFERS</span>
                    <span className="my-3 mx-xxl-0 mx-3">COMPS</span>
                    <span className="my-3 mx-xxl-0 mx-3">SALE</span>
                    <span className="my-3 mx-xxl-0 mx-3">PROBATE</span>
                  </div>
                </div>
                <div className="col-sm-10 mx-auto">
                  <div className={`${styles.services_slider_boxes}  mx-auto `}>
                    <CardSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageRightNo
          no={"03"}
          heading={"SERVICES"}
          pageId={"#gallery1"}
          dark={true}
        />
        <PageleftBars dark={true} />
      </div>
      {/* Garden Gallery */}
      <div className={`container-fluid ${styles.garden_wrapper}`} id="gallery1">
        <div className="row">
          <div className="col-xl-12 col-10 mx-auto">
            <div className={`container`}>
              <div className="row mt-5 pt-5">
                <div
                  className={`col-12 mx-auto pt-5 mt-lg-5 mt-0  ${styles.garden_headings}`}
                >
                  <h1 className="text-center ">GARDEN</h1>
                  <div className={`${styles.garden_slider_boxes} mx-auto `}>
                    <GallerySlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageRightNo
          no={"04"}
          heading={"CATEGORY"}
          pageId={"#chooseus"}
          dark={false}
        />
        <PageleftBars dark={false} />
      </div>

      {/* Why Choose us */}
      <div
        className={`container-fluid pt-5 ${styles.why_chooseus_wrapper}`}
        id="chooseus"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ms-lg-auto text-lg-start text-center">
              <h1 className="mb-5">
                Why You Should <span className="text-sky">Choose Us</span>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className={`col-lg-6 ${styles.chooseus_content}`}>
              <h1>161 +</h1>
            </div>
            <div className={`col-lg-6`}>
              <div className={styles.project_container}>
                <div className={`${styles.project_box}`}>
                  <h6>24H</h6>
                  <p>Expat Rental within 24 Hours</p>
                </div>
                <div className={`${styles.project_box}`}>
                  <h6>10+</h6>
                  <p>More than 10 Years of Experience</p>
                </div>
                <div className={`${styles.project_box}`}>
                  <h6>1300+</h6>
                  <p>More than 1300 Happy clients</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageRightNo
          no={"05"}
          heading={"WHY CHOOS US"}
          pageId={"#gallery2"}
          dark={false}
        />
        <PageleftBars dark={false} />
      </div>

      {/* Image Gallery */}
      <div
        className={`container-fluid py-5 ${styles.image_gallery_wrapper}`}
        id="gallery2"
      >
        <div className="row">
          <div className="col-xxl-9 col-xl-10 col-10 mx-auto">
            <div className={`container py-5`}>
              <div className="row my-5">
                <div
                  className={`col-lg-4 col-md-6 p-0 `}
                >

                  {/* xxxxxxxxxxxxx */}
                  <Slider {...settings} className={`${styles.parent_arrow}`}>
                     
                  <div
                  className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                >
                  <img
                    src="./assets/images/home/gal1.png"
                    className={`${styles.galleryimg}`}
                    alt=""
                  />
                </div>
                 <div
                  className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                >
                  <img
                    src="./assets/images/home/gal2.png"
                    className={`${styles.galleryimg}`}
                    alt=""
                  />
                </div>
                 <div
                  className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                >
                  <img
                    src="./assets/images/home/gal2.png"
                    className={`${styles.galleryimg}`}
                    alt=""
                  />
                </div>
                  </Slider>
                  {/* xxxxxxxxxxxxx */}
                </div>
                <div
                  className={`col-lg-4 col-md-6 p-0`}
                >
               
                   {/* xxxxxxxxxxxxx */}
                   <Slider {...settings} className={`${styles.parent_arrow}`}>
                     
                     <div
                     className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                   >
                     <img
                       src="./assets/images/home/gal1.png"
                       className={`${styles.galleryimg}`}
                       alt=""
                     />
                   </div>
                    <div
                     className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                   >
                     <img
                       src="./assets/images/home/gal2.png"
                       className={`${styles.galleryimg}`}
                       alt=""
                     />
                   </div>
                    <div
                     className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                   >
                     <img
                       src="./assets/images/home/gal2.png"
                       className={`${styles.galleryimg}`}
                       alt=""
                     />
                   </div>
                     </Slider>
                     {/* xxxxxxxxxxxxx */}
                </div>

                <div
                  className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                >
                  <img
                    src="./assets/images/home/gal3.png"
                    className={`${styles.galleryimg}`}
                    alt=""
                  />
                </div>
                <div
                  className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                >
                  <img
                    src="./assets/images/home/gal4.png"
                    className={`${styles.galleryimg}`}
                    alt=""
                  />
                </div>
                <div
                  className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                >
                  <img
                    src="./assets/images/home/gal5.png"
                    className={`${styles.galleryimg}`}
                    alt=""
                  />
                </div>
                <div
                  className={`${styles.galleryimg_div} col-lg-4 col-md-6 p-0 `}
                >
                  <img
                    src="./assets/images/home/gal6.png"
                    className={`${styles.galleryimg}`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageRightNo
          no={"06"}
          heading={"PROPERTY"}
          pageId={"#testimonials"}
          dark={false}
        />
        <PageleftBars dark={false} />
      </div>
      {/* Testimonails */}

      <div
        className={`container-fluid pb-5 ${styles.testimonials_wrapper} `}
        id="testimonials"
      >
        <div className="row">
          <div className="col-xl-8 col-10 mx-auto">
            <div className={`container `}>
              <div className="row ">
                   {/* xxxxxxxxxxxxx */}
                   <Slider {...Testimonialsettings} className={`${styles.parent_arrow}`}>
                   <div className="col-xxl-3 col-sm-6">
                  <div
                    className={`${styles.testimonail_box} mx-auto mb-4 mb-xxl-0`}
                  >
                    <div className={`${styles.testimonail_profile_desc}`}>
                      <p className="text-center px-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Adipisci, vitae.
                      </p>
                      <div className="stars d-flex">
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                      </div>
                    </div>
                    <div className={`${styles.testimonail_profile}`}>
                      <figure>
                        <img
                          src="./assets/images/home/testimonail_img.png"
                          alt="client"
                        />
                      </figure>
                      <h4>LOREM ISPUM</h4>
                      <h6>Designer</h6>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-sm-6">
                  <div
                    className={`${styles.testimonail_box} mx-auto mb-4 mb-xxl-0`}
                  >
                    <div className={`${styles.testimonail_profile_desc_dark}`}>
                      <p className="text-center  px-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Adipisci, vitae.
                      </p>
                      <div className="stars d-flex">
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                      </div>
                    </div>
                    <div className={`${styles.testimonail_profile_dark}`}>
                      <figure>
                        <img
                          src="./assets/images/home/testimonail_img.png"
                          alt="client"
                        />
                      </figure>
                      <h4>LOREM ISPUM</h4>
                      <h6>Designer</h6>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-sm-6">
                  <div
                    className={`${styles.testimonail_box} mx-auto mb-4 mb-xxl-0`}
                  >
                    <div className={`${styles.testimonail_profile_desc}`}>
                      <p className="text-center px-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Adipisci, vitae.
                      </p>
                      <div className="stars d-flex">
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                      </div>
                    </div>
                    <div className={`${styles.testimonail_profile}`}>
                      <figure>
                        <img
                          src="./assets/images/home/testimonail_img.png"
                          alt="client"
                        />
                      </figure>
                      <h4>LOREM ISPUM</h4>
                      <h6>Designer</h6>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-sm-6">
                  <div
                    className={`${styles.testimonail_box} mx-auto mb-xxl-0 mb-4`}
                  >
                    <div className={`${styles.testimonail_profile_desc_dark}`}>
                      <p className="text-center px-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Adipisci, vitae.
                      </p>
                      <div className="stars d-flex">
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                        <FaStar className={`${styles.star_icon}`} />
                      </div>
                    </div>
                    <div className={`${styles.testimonail_profile_dark}`}>
                      <figure>
                        <img
                          src="./assets/images/home/testimonail_img.png"
                          alt=""
                        />
                      </figure>
                      <h4>LOREM ISPUM</h4>
                      <h6>Designer</h6>
                    </div>
                  </div>
                </div>
                   
                     </Slider>
                     {/* xxxxxxxxxxxxx */}
     
              </div>
            </div>
          </div>
        </div>
        <PageRightNo
          no={"07"}
          heading={"TESTIMONIALS"}
          pageId={"#footer"}
          dark={true}
        />
        <PageleftBars dark={true} />
      </div>
      {/*  Footer */}

      <Footer />
    </>
  );
}

export default Home;





// carousel arrow button components...

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
      <div>
          <MdOutlineArrowForwardIos className={`${className} ${styles.arrowStyle} `} style={{ color: 'white', bottom: '0', right: '0%', zIndex: 100 }} onClick={onClick} />
      </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
      <div >
          <MdOutlineArrowBackIosNew className={`${className} ${styles.arrowStyle} `} style={{ fontSize: '100px', color: 'white', bottom: '0', left: '0%' , zIndex: 100 }} onClick={onClick} />
      </div>
  );
};


