import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Success} from "../Redux/reducers/userSlice";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { API_BASE_URL } from "../config";
import styles from "./signup.module.css";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing icons

export default function Signup() {

  const [crendentials, setCrendentials] = useState({
    username: "",
    email: "",
    password: "",
    cpassword: "",
    specialization:"",
    experienceYear:""
  });
  const [check, setCheck] = useState(false)
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  // alter validation error..
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);

    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // validation..
  const formValidation = () => {
    if (
      crendentials.username &&
      crendentials.email &&
      crendentials.password &&
      crendentials.cpassword
    ) {
      if (crendentials.password === crendentials.cpassword) {
        const passwordRegex =
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        var emailRegex =/^\w+[\w.-]*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

        if (emailRegex.test(crendentials.email)) {
          if (crendentials.password.length >= 8) {
            return true;
          } else {
            setSuccessAlert(false);
            alterValidationMessage(
              "Password must be atleast 8 characters!"
            );
          }
        } else {
          setSuccessAlert(false);
          alterValidationMessage("Email is not valid");
        }
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Passwords Not Match!");
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    
    e.preventDefault();
    if (formValidation()) {
      setLoader(true);
      try {
        const res = await axios.post(`${API_BASE_URL}/auth/register`, {
          username: crendentials.username,
          email: crendentials.email,
          password: crendentials.password,
          role: check ? "agent" : "user",
          specialization:crendentials.specialization,
          experienceYear:crendentials.experienceYear,
        });
        setLoader(false);
        console.log("signup res", res);
        dispatch(Success(res.data.user))
       
        navigate("/");
      } catch (error) {
        setLoader(false);
        setSuccessAlert(false);
        if (error?.response?.status === 403) {
          alterValidationMessage(error?.response?.data?.message);
        }else{
          alterValidationMessage(error?.message);
        }
      }
    }
  };

  const handleInputs = (e) => {
    setCrendentials({ ...crendentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <div className={`${styles.body}`}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <div className={`${styles.signupHeader}`}>
                  <h1 className="text-center text-white fw-bold">SIGN UP</h1>
                </div>

                <div className={`${styles.signupFormContainer} `}>
                  <Form className="text-center ">
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.signupField}`}
                        type="text"
                        value={crendentials.username}
                        placeholder="Name"
                        name="username"
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.signupField}`}
                        type="email"
                        value={crendentials.email}
                        placeholder="Email"
                        name="email"
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group
                      className={`mb-3 position-relative`}
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        className={`${styles.signupField}`}
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={crendentials.password}
                        onChange={handleInputs}
                      />
                       <span
                        className={styles.passwordToggleIcon}
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </Form.Group>

                    <Form.Group
                      className={`mb-3`}
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        className={`${styles.signupField}`}
                        type="password"
                        name="cpassword"
                        placeholder="ConfirmPassword"
                        value={crendentials.cpassword}
                        onChange={handleInputs}
                      />
                    </Form.Group>
                    <h6 className="text-white d-flex">
                        <input type="checkbox" value={check} onChange={(e)=> setCheck(e.target.checked)} />
                        <Link
                          className="text-decoration-none"
                          style={{ color: "#97d2ec" }}
                          to="/reset-password"
                        >
                        &nbsp;  As Lawyer
                        </Link>
                      </h6>
                      {
                        check &&
                        <>

                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.signupField}`}
                        type="text"
                        value={crendentials.specialization}
                        placeholder="Specialization"
                        name="specialization"
                        onChange={handleInputs}
                      />
                    </Form.Group>
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.signupField}`}
                        type="number"
                        value={crendentials.experienceYear}
                        placeholder="Years of Experience"
                        name="experienceYear"
                        onChange={handleInputs}
                      />
                    </Form.Group>

                        
                        
                        </>
                      }
                    {loader ? (
                      <Button
                        variant="primary"
                        className={`${styles.signupBtn}`}
                        type="submit"
                        disabled
                      >
                        Sign Up
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        className={`${styles.signupBtn}`}
                        type="submit"
                        onClick={onSubmit}
                      >
                        Sign Up
                      </Button>
                    )}
                  </Form>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <h5 className="text-white">OR</h5>
              </div>

              <div className="d-flex mt-4">
                <Link to="#">
                  <img
                    src="./assets/images/authAssets/facebook.png"
                    className="px-1"
                    alt="facebook"
                  />
                </Link>
                <Link to="#">
                  <img
                    src="./assets/images/authAssets/twitter.png"
                    className="px-1"
                    alt="twitter"
                  />
                </Link>
              </div>

              <div>
                <h6 className="text-white mt-5">
                  Already Member?{" "}
                  <Link
                    className={`text-decoration-none ${styles.button_color}`}
                    to="/login"
                  >
                    Login
                  </Link>
                </h6>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
