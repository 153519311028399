import axios from "axios";
import { useContext } from "react";
import styles from "./user.module.css";
import React, { useState } from "react";
import { API_BASE_URL } from "../../../config";
import myContext from "../../../context/context";
import TopNav from "../../components/dashbaordHeader/TopNav";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";


function AddUser() {


  const { dclose } = useContext(myContext);
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [userAdd, setUserAdd] = useState({
    username: "",
    email: "",
    password: "",
    cpassword: "",
    role: "user",
  });

  
  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);
    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };


// validation 
  const formValidation = () => {
    if (
      userAdd.username &&
      userAdd.email &&
      userAdd.password &&
      userAdd.cpassword
    ) {
      if (userAdd.password === userAdd.cpassword) {
        const passwordRegex =
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        var emailRegex =/^\w+[\w.-]*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

        if (emailRegex.test(userAdd.email)) {
          if (passwordRegex.test(userAdd.password)) {
            return true;
          } else {
            setSuccessAlert(false);
            alterValidationMessage(
              "Password contain 6 to 16 valid characters and at least one number and on special character!"
            );
          }
        } else {
          setSuccessAlert(false);
          alterValidationMessage(
            "Email is not valid"
          );
        }
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Passwords Not Match!");
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };


  const submitUser = async () => {
    if (formValidation()) {
    try {
      await axios
      .post(`${API_BASE_URL}/auth/register`, userAdd)
      .then((res) => {
        setSuccessAlert(true);
        alterValidationMessage("Successfully Added!");
        setUserAdd({
          username: "",
          email: "",
          password: "",
          cpassword: "",
          role: "user",
        });
      });
    } catch (error) {
      console.log(error);
      setSuccessAlert(false);
      alterValidationMessage("Unable to Add User. retry or try later");
    }
  }
  };


  const handleInputs = (e) => {
    setUserAdd({ ...userAdd, [e.target.name]: e.target.value });
  };


  return (
    <>
    {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes/>
          </div>

          <div className="row mt-3">
          <h1 className="text-center mt-5">Add User</h1>
             {/* xxxxxxx Add New User xxxxxxxxxxxx */}
             <div className={`${styles.add_user_form}`}>
              <div className="mb-3">
                <input
                  type="text"
                  name="username"
                  className={`${styles.add_user_field} form-control`}
                  placeholder="Enter User Name"
                  value={userAdd.username}
                  onChange={handleInputs}
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  name="email"
                  className={`${styles.add_user_field} form-control`}
                  placeholder="Enter User Email"
                  value={userAdd.email}
                  onChange={handleInputs}
                />
              </div>

              <div className="mb-3">
                <input
                  type="password"
                  name="password"
                  className={`${styles.add_user_field} form-control`}
                  placeholder="Enter Password"
                  value={userAdd.password}
                  onChange={handleInputs}
                />
              </div>

              <div className="mb-3">
                <input
                  type="password"
                  name="cpassword"
                  className={`${styles.add_user_field} form-control`}
                  placeholder="Confirm Password"
                  value={userAdd.cpassword}
                  onChange={handleInputs}
                />
              </div>
              

              <div className="mb-3">
                <button onClick={submitUser}
                className={`${styles.submit_user_btn}`}
                >Add User</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}


export default AddUser;
