import axios from "axios";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { API_BASE_URL } from "../../../config";
import myContext from "../../../context/context";
import React, { useEffect, useState } from "react";
import styles from "./completedbookings.module.css";
import ModalBookingStatus from "./ModalBookingStatus";
import TopNav from "../../components/dashbaordHeader/TopNav";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { useSelector } from "react-redux";

function CompletedBookings() {
  const { dclose } = useContext(myContext);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allBookings, setAllBookings] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);
  const userData = useSelector((state) => state?.data);

  const handleModal = (item) => {
    setShowModal(true);
    setBookingStatus(item);
  };

  const getAllBookings = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/bookings`)
      .then((res) => {
        setAllBookings(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllBookings();
  }, []);
  const assignedBookingAgent = allBookings?.filter((item)=> item?.propertyID?.assignedAgent[0] == userData?._id) 
  console.log(assignedBookingAgent)
  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          {/* User Listing  */}
          <div className="row">
            <h1 className="text-center py-4">Update Booking Status</h1>
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th>Property Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Booking Date</th>
                    <th>Booking Time</th>
                    <th>Booking Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="7" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : userData?.role == "agent" ? 
                  (
                  
                      assignedBookingAgent?.map((item) => {
                        return (
                          <>
                            <tr key={item._id}>
                              <td>{item.propertyTitle}</td>
                              <td>{item.username}</td>
                              <td>{item.email}</td>
                              <td>{item.bookingDate}</td>
                              <td>{item.bookingTime}</td>
                              <td>{item.bookingStatus}</td>
                              <td className="text-center">
                                <Button
                                  className={`${styles.table_btns}`}
                                  onClick={() => handleModal(item)}
                                >
                                  Update Status
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                  )
                  
                  : (
                    allBookings
                      ?.filter(
                        (item) =>
                          item.bookingStatus === "Available" ||
                          item.bookingStatus === "Not Available"
                      )
                      .map((item) => {
                        return (
                          <>
                            <tr key={item._id}>
                              <td>{item.propertyTitle}</td>
                              <td>{item.username}</td>
                              <td>{item.email}</td>
                              <td>{item.bookingDate}</td>
                              <td>{item.bookingTime}</td>
                              <td>{item.bookingStatus}</td>
                              <td className="text-center">
                                <Button
                                  className={`${styles.table_btns}`}
                                  onClick={() => handleModal(item)}
                                >
                                  Update Status
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <ModalBookingStatus
          setShowModal={setShowModal}
          bookingData={bookingStatus}
          getAllBookings={getAllBookings}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default CompletedBookings;
