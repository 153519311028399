import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "../AgentList/agentList.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";
import ModalAgent from "./ModalAgent";




function AgentList() {

  const { dclose } = useContext(myContext);
  const [allUsers, setAllUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const openModal = (id) => {
    setModalShow(true);
    setUserData(id);
  };
  const getAllUsers = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/all-users/`)
      .then((res) => {
        setAllUsers(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const deleteListing = ( id ) => {
    axios
      .delete(`${API_BASE_URL}/all-users/delete-user/${id}`)
      .then((res) => {
        if (res.status === 200) {
          NotificationAlert("Deleted Successfully", "success");
          getAllUsers()
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationAlert("Failed to delete", "failed");
      });
  };

const handleActivation = (id, active) =>{
  axios
   .patch(`${API_BASE_URL}/all-users/activation/${id}`, {active})
   .then((res) => {
     console.log(res)
      if (res?.data?.success) {
        getAllUsers()
        NotificationAlert(res?.data?.message, "success");
      }
    })
   .catch((err) => {
      console.log(err);
      NotificationAlert("Failed to activate", "failed");
    });
  }
  


  const filteredLawyer = allUsers.filter((item) => {
    return item.role === "agent";
  });



  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
           <CountBoxes/>
          </div>

       


          {/* Lawyer Listing  */}
          <div className="row">
            <h1 className="text-center py-4">
              Lawyer Listingss
            </h1>
            <div className={`${styles.table_wrapper}`}>
            <table className={`${styles.table_container}`}>
              <thead className={`${styles.table_header}`}>
              <tr>
                <th  className="text-center">#</th>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th className="text-center">Deletes</th>
                <th className="text-center">Assign Property</th>
                <th className="text-center">Activate</th>
              </tr>
              </thead>
              <tbody  className={`${styles.table_body}`}>
              {loader ?  <tr><td colSpan="5" className="mx-auto text-center">Loading...</td></tr>  : 
              filteredLawyer.map((item, i) => {
                return (
                  <>
                    <tr key={item.id}>
                      <td  className="text-center">{i+1}</td>
                      <td>{item.username}</td>
                      <td>{item.email}</td>
                      <td>{item.role}</td>
                      <td className="text-center">
                        <Button variant="dark" className={styles.table_btns} onClick={()=>deleteListing(item._id)}>
                          Delete
                        </Button>
                      </td>
                      <td className="text-center">
                        <Button variant="dark" className={styles.table_btns} onClick={()=> openModal(item._id)}>
                         Assign
                        </Button>
                      </td>
                      <td className="text-center">
                        <Button variant="dark" className={item?.activate ? `${styles.btn_success}` :`${styles.table_btns}`} onClick={()=> item?.activate ? handleActivation(item._id,false) :handleActivation(item._id,true) }>
                            {item?.activate ? "Active" : "Activate"}
                        </Button>
                      </td>
                    </tr>
                  </>
                );
              })}
             </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <ModalAgent userData={userData} setModalShow={setModalShow} />
      ) : (
        ""
      )}
    </>
  );
}




export default AgentList;