import styles from "./Pageright.module.css";

const PageRightNo = ({ no, heading, pageId, dark }) => {
  return (
    <>
      <div
        className={
          dark
            ? `${styles.page_right_container_dark}`
            : `${styles.page_right_container}`
        }
      >
        <div className="row">
          <div className={`${styles.pageright_1} col-12  ps-5`}>
            <h1>{no}</h1>
            <span
              className={dark ? `${styles.heading_dark}` : `${styles.heading}`}
            >
              {heading}
            </span>
            <a
              href={pageId}
              className={
                dark ? `${styles.colorText_dark}` : `${styles.colorText}`
              }
            >
              <span
                className={
                  dark ? `${styles.scroll_down_dark}` : `${styles.scroll_down}`
                }
              >
                scroll down
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageRightNo;
