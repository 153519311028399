import React from 'react'
import styles from './notfound.module.css'
const Activate = () => {

    return (
        <div class={styles.container}>
            <h1>Account Pending Approval</h1>
            <p>Your account is currently pending approval by an administrator. Please check back later.</p>
            <a href="home.html" class="button">Go to Home</a>
        </div>
    )
}

export default Activate
