import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";

const ModalManager = ({ setShowModal, propertyObj }) => {

  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [updateUser, setUpdateUser] = useState({
    username: propertyObj.username,
    email: propertyObj.email,
    password: propertyObj.password,
    role: "manager",
  });
  
  
  const { username, email, password, role } = updateUser;
  const handleInputs = (e) => {
    setUpdateUser({ ...updateUser, [e.target.name]: e.target.value });
  };

  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);

    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };

  // validation
  const formValidation = () => {
    if (updateUser.username && updateUser.email && updateUser.password) {
      const passwordRegex =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
      var emailRegex =/^\w+[\w.-]*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

      if (emailRegex.test(updateUser.email)) {
        if (passwordRegex.test(updateUser.password)) {
          return true;
        } else {
          setSuccessAlert(false);
          alterValidationMessage(
            "Password contain 6 to 16 valid characters and at least one number and on special character!"
          );
        }
      } else {
        setSuccessAlert(false);
        alterValidationMessage("Email is not valid");
      }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValidation()) {
      try {
        const res = await axios.patch(
          `${API_BASE_URL}/all-users/update-user/${propertyObj._id}`,
          updateUser
        );
        setUpdateUser(res.data);
        NotificationAlert("Updated Successfully", "success");
        setShowModal(false);
      } catch (error) {
        console.log(error);
        setSuccessAlert(false);
        NotificationAlert("Failed to update", "failed");
        alterValidationMessage("Unable to update try later or retry");
      }
    }
  };

  return (
    <>
      {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <div className="modal_wrapper text-center">
        <div className="modal-form">
          <span className="close-modal" onClick={() => setShowModal(false)}>
            X
          </span>

          <div className="form-input-container">
            <label>username:</label>
            <br />
            <input
              type="text"
              value={username}
              name="username"
              onChange={handleInputs}
            />
          </div>
          <div className="form-input-container">
            <label>email:</label>
            <br />
            <input
              type="text"
              name="email"
              value={email}
              onChange={handleInputs}
            />
          </div>
          <div className="form-input-container">
            <label>password:</label>
            <br />
            <input
              type="text"
              name="password"
              value={password}
              onChange={handleInputs}
            />
          </div>
          <div className="form-input-container">
            <label>role:</label>
            <br />
            <input type="text" value={role} />
          </div>

          <div>
            <button className="modal-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalManager;
