import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./contact.module.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "../../componenets/Header/Header";
import Footer from "../../componenets/Footer/Footer";
import axios from "axios";
import { API_BASE_URL } from "../../config";



export default function Contact() {
  
  // contact form state...
  const [crendentials, setCrendentials] = useState({
    username: "",
    email: "",
    phone: "",
    message: "",
  });

  // validation alert...
  const [validationAlert, setValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);

  // Handle Contact Form Inputs..
  const handleInputs = (e) => {
    setCrendentials({ ...crendentials, [e.target.name]: e.target.value });
  };
  
  
  // alter validation error
  const alterValidationMessage = (message) => {
    setValidationAlert(true);
    setAlertMessage(message);

    setTimeout(() => {
      setValidationAlert(false);
    }, 3000);
  };


// validation 
  const formValidation = () => {
    if (
      crendentials.username &&
      crendentials.email &&
      crendentials.phone &&
      crendentials.message
    ) {
        
        var emailRegex =/^\w+[\w.-]*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
         var phoneNoRegex =  /^\+(?:[0-9] ?){6,14}[0-9]$/;

        if (emailRegex.test(crendentials.email)) {
            if (phoneNoRegex.test(crendentials.phone)) {
              return true;
            }else{
              setSuccessAlert(false);
              alterValidationMessage("Phone number is not valid!");
            }
          }else{
            setSuccessAlert(false);
            alterValidationMessage("Email is not valid!");
          }
    } else {
      setSuccessAlert(false);
      alterValidationMessage("All field required");
    }
    return false;
  };

  
  const onSubmit = async  (e) => {
    e.preventDefault();
    
    if (formValidation()) {
      try {
        await axios.post(`${API_BASE_URL}/contact`, crendentials);
        setSuccessAlert(true);
        alterValidationMessage("Message Send Successfully");
        setCrendentials({
          username: "",
          email: "",
          phone: "",
          message: "",
        })
      } catch (err) {
        console.log(err);
        setSuccessAlert(false);
        alterValidationMessage("Unable to Send Message, Try later or retry");
      }
      return;
    }
  };


  return (
    <>
     {validationAlert ? (
        <div
          className={
            successAlert
              ? "alert alert-success validation-alert d-flex justify-content-between"
              : "alert alert-danger validation-alert d-flex justify-content-between"
          }
          role="alert"
          onClick={() => setValidationAlert(false)}
        >
          <span>{alertMessage}</span>
          <span className="close-alert">X</span>
        </div>
      ) : null}
      <Header />
      <div className={`${styles.body}`}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <div className={`${styles.signupHeader}`}>
                  <h1 className="text-center text-white fw-bold">Contact Us</h1>
                </div>

                <div className={`${styles.signupFormContainer} `}>
                  <Form className="text-center ">
                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.signupField}`}
                        type="text"
                        value={crendentials.username}
                        placeholder="Full Name"
                        name="username"
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.signupField}`}
                        type="email"
                        value={crendentials.email}
                        placeholder="Email"
                        name="email"
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group className={`mb-3`} controlId="formBasicEmail">
                      <Form.Control
                        className={`${styles.signupField}`}
                        type="tel" 
                        value={crendentials.phone}
                        placeholder="Phone"
                        name="phone"
                        onChange={handleInputs}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        className={`${styles.message}`}
                        placeholder="Your Message!"
                        value={crendentials.message}
                        name="message"
                        onChange={handleInputs}
                        rows={5}
                      />
                    </Form.Group>

                    <Button
                      variant="primary"
                      className={`${styles.signupBtn}`}
                      type="submit"
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}


