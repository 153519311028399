import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./deleteproperty.module.css";
import myContext from "../../../context/context";
import Button from "react-bootstrap/Button";
import { useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";

const DeleteProperty = () => {
  
  const { dclose } = useContext(myContext);
  const [updateProperties, setUpdatedProperties] = useState([]);
  const [loader, setLoader] = useState(false);

 

  const getUpdateProperties = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/properties/all-properties`)
      .then((res) => {
        setUpdatedProperties(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUpdateProperties();
  }, []);


  const handleModal = (item) => {
    axios
      .delete(`${API_BASE_URL}/properties/delete-property/${item._id}`)
      .then((res) => {
        if (res.status === 200) {
          NotificationAlert("Property Delete Successfully", "success");
          getUpdateProperties();
        }
      })
      .catch((error) => {
        NotificationAlert("Failed to delete property", "failed");
        console.log(error);
      });
  };

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="row">
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Property Title</th>
                    <th>Address</th>
                    <th>Price</th>
                    <th className="text-center">Deletes</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? (
                    <tr>
                      <td colSpan="5" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    updateProperties.map((item, i) => {
                      return (
                        <>
                          <tr key={item._id}>
                            <td className="text-center">{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.address}</td>
                            <td>{item.price}</td>
                            <td className="text-center">
                              <Button
                                variant="dark"
                                className={styles.table_btns}
                                onClick={() => handleModal(item)}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteProperty;
