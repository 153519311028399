import styles from "./galleryview.module.css";

const GalleryView = ({ showGallery, setShowGallery, imgLinks }) => {
  return (
    <>
      <div
        className={
          showGallery
            ? `${styles.galleryview_wrapper} container-fluid`
            : `${styles.galleryview_wrapper_hide} container-fluid`
        }
      >
        <span className={styles.close} onClick={() => setShowGallery(false)}>
          X
        </span>

        <div
          id="carouselExampleFade"
          className={`${styles.slider_gallery_wrapper} carousel slide carousel-fade`}
        >
          <div className="carousel-inner">
            {imgLinks?.map((link, i) => (
              <div
                key={i}
                className={`carousel-item ${styles.sliderShow} active`}
              >
                <img src={link} className="d-block w-100" alt="..." />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span
              className={`${styles.btn_prev} carousel-control-prev-icon`}
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span
              className={`${styles.btn_next} carousel-control-next-icon`}
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default GalleryView;
