import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";

const ModalPersonalRep = ({ userData, setModalShow }) => {
    

  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const userId = useSelector((state) => state.data._id);


  const getProperties = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/properties/all-properties`);
      setProperties(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  const onChangeSelect = (e) => {
    setSelectedProperty(e.target.value);
  };

  const unSold = properties.filter((item) => item.status === "unsold");

  const assignMents = async () => {
    try {
      const res = await axios.patch(
        `${API_BASE_URL}/properties/${selectedProperty}/presonalrep-assignments`,
        {
            assignedPersonalRep: userData,
            PRassignedBy: userId
        }
      );

      if (res.status === 200) {
        NotificationAlert('Assigned Succefully', "success");
        setModalShow(false);
      }
    } catch (error) {
      console.log(error);
      NotificationAlert(error.response.data.message, "failed");
    }
  };

  return (
    <>
      <div className="modal_wrapper text-center">
        <div className="modal-form">
          <span className="close-modal" onClick={() => setModalShow(false)}>
            X
          </span>

          <h3>Select Property To Assign</h3>

          <select defaultValue={"Default"} onChange={onChangeSelect}>
            <option value={"Default"} disabled>
              Select Property
            </option>
            {unSold.map((item, index) => {
              return (
                <option key={index + 1} value={item._id}>
                  {item.title}
                </option>
              );
            })}
          </select>
          <div>
            <button className="btn btn-primary" onClick={assignMents}>
              Assign
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPersonalRep;
