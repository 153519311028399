import styles from "../../dashboardpages/Dashboardmain/dashboard.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const MainChart = ({property}) => {


  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <div className={`${styles.main_chart_container} mx-auto`}>
            <h6 className="ms-2 pt-3 mb-4">
              PROPERTIES PRICE COMPARISION
            </h6>
           
            <div style={{ width: "100%", height: "80%" }}>
              <ResponsiveContainer width="100%" height="100%" >
                <BarChart
                  data={property}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="title" />
                  <YAxis dataKey="price" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    barSize={20}
                    barGap={4}
                    dataKey="price"
                    stackId="a"
                    fill="#1976D2"
                  />
                 
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainChart;
