import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import styles from "./Header.module.css";
import Sidebar from "./Sidebar";
import myContext from "../../context/context";
import { FaAlignLeft } from "react-icons/fa";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import { Logout } from "../../Redux/reducers/userSlice";

function Header({ isBlur }) {
     
  const userData = useSelector((state) => state);
  const [logoutBtn, setLogoutBtn] = useState(false);
  const { openSidebar } = useContext(myContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(Logout());
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Sidebar />
      <Navbar
        collapseOnSelect
        variant="dark"
        className={isBlur ? `${styles.header_blur} ` : `${styles.header} `}
        id="header"
      >
        <Container fluid>
          {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
          <Navbar.Collapse
            id="navbarScroll"
            className="d-flex justify-content-between"
          >
            <Navbar.Brand href="#" className="ms-lg-4">
              <FaAlignLeft className={styles.nav_menu} onClick={openSidebar} />
            </Navbar.Brand>
            <Nav className="mx-auto my-2 my-lg-0" navbarScroll>
              <NavLink to="/">
                <img
                  src="../../assets/images/home/realstate.png"
                  alt=""
                  className={styles.logo}
                />
              </NavLink>
            </Nav>
            {userData.isLoggedIn ? (
              <span
                className={`${styles.user_pic_container}`}
                onClick={() => setLogoutBtn(!logoutBtn)}
              >
                <img
                  src={`${API_BASE_URL}/${userData?.data?.avatar}`}
                  className={styles.login_user}
                  alt="user"
                />
                <span className={`${styles.user_name} ms-3`}>
                  {userData?.data?.username}
                </span>
                <p>
                  {userData?.data?.role}
                </p>
                <span
                  className={
                    logoutBtn
                      ? `${styles.logout_btn}`
                      : `${styles.logout_btn_hide}`
                  }
                >
                  <button onClick={handleLogout}>Logout</button>
                </span>
              </span>
            ) : (
              ""
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
