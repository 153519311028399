import { useEffect } from "react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TeamAssociateConcernOnlyProtection = ({ Page }) => {
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("persist:user");
    const userData = JSON.parse(user);
    const userRole = JSON.parse(userData?.data);
    setUserType(userRole?.role);
  }, []);

  if (
    userType !== "super admin" &&
    userType !== "manager" &&
    userType !== "admin" &&
    userType !== "team associates"
  ) {
    navigate("/");
    return;
  } else {
    return (
      <>
        <Page />
      </>
    );
  }
};

export default TeamAssociateConcernOnlyProtection;
