import axios from "axios";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../../config";
import myContext from "../../../context/context";
import styles from "./listedproperties.module.css";
import React, { useEffect, useState } from "react";
import TopNav from "../../components/dashbaordHeader/TopNav";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";



function AssingedPersonalRep() {


  const { dclose } = useContext(myContext);
  const [loader, setLoader] = useState(false);
  const [listedProperties, setListedProperties] = useState([]);
  const userData = useSelector((state) => state.data);

  const getlistedProperties = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/properties/all-properties`)
      .then((res) => {
        setListedProperties(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getlistedProperties();
  }, []);

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="row">
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Property Title</th>
                    <th>Address</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {loader ? 
                  <tr><td colSpan="4" className="mx-auto text-center">Loading...</td></tr>
                  :
                  userData?.role === "personal rep"
                    ? listedProperties
                        ?.filter(
                          (item) => item?.assignedPersonalRep
                          [0] === userData?._id
                        )
                        ?.map((item, i) => {
                          return (
                            <>
                              <tr key={item._id}>
                                <td className="text-center">{i + 1}</td>
                                <td>{item.title}</td>
                                <td>{item.address}</td>
                                <td>{item.price}</td>
                              </tr>
                            </>
                          );
                        })
                    : listedProperties.map((item, i) => {
                        return (
                          <>
                            <tr key={item._id}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item.title}</td>
                              <td>{item.address}</td>
                              <td>{item.price}</td>
                            </tr>
                          </>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssingedPersonalRep;
