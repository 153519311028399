import React, { useEffect, useState } from "react";
import DSidebar from "../../components/dashbaordHeader/Dsidebar";
import TopNav from "../../components/dashbaordHeader/TopNav";
import styles from "./blockuser.module.css";
import myContext from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { API_BASE_URL } from "../../../config";
import CountBoxes from "../../components/CountBoxes/CountBoxes";
import { NotificationAlert } from "../../../componenets/NotificationAlert/NotificationAlert";

function BlockUser() {
  const { dclose } = useContext(myContext);
  const [allUsers, setAllUsers] = useState([]);
  const [filterUser, setFilterUsers] = useState([]);
  const [loader, setLoader] = useState(false);

  const getAllUsers = () => {
    setLoader(true);
    axios
      .get(`${API_BASE_URL}/all-users`)
      .then((res) => {
        setAllUsers(res.data);
        setFilterUsers(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const blockUser = (id) => {
    console.log(id);
    axios
      .patch(`${API_BASE_URL}/all-users/update-user/${id}`, { role: "block" })
      .then((res) => {
        if (res.status === 200) {
          NotificationAlert("Deleted Successfully", "success");
          getAllUsers()
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationAlert("Failed to delete", "failed");
      });
  };

  const onChangeRole = (e) => {
    if (e.target.value === "all") {
      setFilterUsers(allUsers);
    } else {
      const filterUsers = allUsers.filter((user) => {
        return user.role === e.target.value;
      });
      setFilterUsers(filterUsers);
    }
  };

  return (
    <>
      <DSidebar />
      <TopNav />
      <div
        className={
          dclose ? `${styles.DashboardFull}` : `${styles.Dashboard} pb-5`
        }
      >
        <div className="container">
          <div className="row mx-auto mt-3">
            <CountBoxes />
          </div>

          <div className="select_role_container mt-5">
            <label className="me-3">Select Roles to Block User</label>
            <select
              className={styles.select_roles_field}
              onChange={onChangeRole}
            >
              <option value="all">All Types</option>
              <option value="user">Users</option>
              <option value="block">Block Users</option>
              <option value="manager">Managers</option>
              <option value="attorney">Attornies</option>
              <option value="personal rep">Persoanl Reps</option>
              <option value="team associates">Team Associate</option>
              <option value="agent">Agents</option>
            </select>
          </div>

          {/* Disply user Role  */}
          <div className="row">
            <div className={`${styles.table_wrapper}`}>
              <table className={`${styles.table_container}`}>
                <thead className={`${styles.table_header}`}>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th className="text-center">Blocked</th>
                  </tr>
                </thead>
                <tbody className={`${styles.table_body}`}>
                  {filterUser.length > 0 && filterUser ? (
                    loader ? (
                      <tr>
                        <td colSpan="5" className="mx-auto text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : (
                      filterUser?.filter((user) => user.role !== "block")?.map((item, i) => {
                        return (
                          <>
                            <tr key={item?._id}>
                              <td className="text-center">{i + 1}</td>
                              <td>{item?.username}</td>
                              <td>{item?.email}</td>
                              <td>{item?.role}</td>
                              <td className="text-center">
                                <Button
                                  variant="dark"
                                  className={styles?.table_btns}
                                  onClick={() => blockUser(item?._id)}
                                >
                                  Block
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    )
                  ) : (
                    <tr>
                      <td colSpan="5" className="mx-auto text-center">
                        Loading...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockUser;
